import axios from "../axios";
import _get from "lodash/get";
import * as TYPES from "./appActionTypes";

export const onAppBusy = value => ({
    type: TYPES.APP_BUSY,
    value
});

export const setTimeZone = value => ({
    type: TYPES.TIMEZONE,
    value
});

export const preCheck = value => ({
    type: TYPES.PRE_CHECK,
    value
});

export const setSelectedAccountId = value => ({
    type: TYPES.SELECTED_ACCOUNT_ID,
    value
});

export const setAccountProperties = value => ({
    type: TYPES.ACCOUNT_PROPERTIES,
    value
});

export const setConfig = value => ({
    type: TYPES.APP_CONFIG,
    value
});

export const onAccountBusy = value => ({
    type: TYPES.ACCOUNT_BUSY,
    value
});

export const onAccountUpdateBusy = value => ({
    type: TYPES.ACCOUNT_UPDATE_BUSY,
    value
});

export function getConfig() {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        return axios({
            url: `${BASE_URL}/cloud/config`,
            method: "get",
            params: {}
        })
            .then(response => {
                const _response = _get(response, "data", {});
                dispatch(setConfig(_response));
                return _response;
            })
            .catch(error => {
                return _get(error, "response.data.errors[0].message");
            })
            .then(response => {
                return response;
            });
    };
}

export function getAccountProperties(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        dispatch(onAccountBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/account/properties`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                dispatch(setAccountProperties(_response));
                return _response;
            })
            .catch(error => {
                return _get(error, "response.data.errors[0].message");
            })
            .then(response => {
                dispatch(onAccountBusy(false));
                return response;
            });
    };
}

export function updateAccountProperties(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");
        const accountPropertiesId = params.accountSettingsId;
        dispatch(onAccountUpdateBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/account/properties/${accountPropertiesId}`,
            method: "put",
            data: params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                return _get(error, "response.data.errors[0].message");
            })
            .then(response => {
                dispatch(onAccountUpdateBusy(false));
                return response;
            });
    };
}

export function getImageDownloadUrl(params = {}) {
    return async (dispatch, getState) => {
        try {
            const BASE_URL = _get(getState(), "appData.BASE_URL");
            const accountId = _get(
                getState(),
                "authData.profile.user.accountId"
            );

            dispatch(onAccountBusy(true));
            const response = await axios({
                url: `${BASE_URL}/manager/${accountId}/imageGetUrl`,
                method: "get",
                params
            });
            const _response = _get(response, "data", {});

            // console.log("_response ", _response);
            return _response;
        } catch (error) {
            return _get(error, "response.data.errors[0].message");
        }
    };
}

export function downloadImage(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        return axios({
            url: `${BASE_URL}/manager/${accountId}/image`,
            method: "GET",
            params,
            responseType: "blob",
            headers: {
                Accept: "application/pdf"
            }
        })
            .then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", params.name);
                document.body.appendChild(link);
                link.click();
                return response;
            })
            .catch(error => {
                const errMsg = _get(error, "response.statusText");
                return _get(error, "response.data.errors[0].message", errMsg);
            })
            .then((response = {}) => {
                return response;
            });
    };
}
