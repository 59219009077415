import { React, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {
  getMenusItems,
  getMenusGroups,
} from "../../../actions/menu/menuActions";
import { CircularProgress } from "@material-ui/core";
import { useSearchParams, useParams } from "react-router-dom";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import Availability from "./Availability";
import EditModal from "./EditModal";

const styles = (theme) => ({
  root: {
    margin: 10,
  },
  paper: {
    margin: 15,
    overflow: "hidden",
  },
  title: {
    flexGrow: 1,
  },
  saveButton: {
    float: "right",
    marginRight: 5,
  },
});

function MenuItemDetail(props) {
  const { classes } = props;
  const [item, setItem] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [selGroup, setSelGroup] = useState("");
  const [selMenu, setSelMenu] = useState("");

  const [searchParams] = useSearchParams();

  let params = useParams();

  async function getItem() {
    try {
      setLoading(true);

      let response = await props.getMenusItems({ id: params.itemId });

      if (response && response.id) {
        setItem(response);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  useEffect(() => {
    getItem();
    // eslint-disable-next-line
  }, []);

  // console.log("item == ", item);

  return (
    <Card className={classes.root}>
      {isEdit && <EditModal data={item} open={isEdit} handleClose={() => setIsEdit(false)} />}
      <CardContent>
        <Grid container className={classes.root} spacing={2}>
          <Grid item md={12} xs={12}>
            <Typography variant="h5" component="h2">
              {item.name}
              <Button
                className={classes.saveButton}
                color="primary"
                variant="contained"
                size={"small"}
                onClick={() => setIsEdit(true)}
              >
                {"EDIT"}
              </Button>
            </Typography>
            <Typography variant="body2" component="p" mt="5">
              {item.description}
            </Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <Availability data={item} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMenusItems,
      getMenusGroups,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MenuItemDetail)
);
