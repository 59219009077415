import { combineReducers } from "redux";
import app from "../app/appReducer";
import auth from "../auth/authReducer";
import account from "../account/accountReducer";
import menu from "../menu/menuReducer";
import contact from "../contact/contactReducer";
import user from "./user";
import log from "./log";
import location from "./location";
import documentData from "./document";
import notification from "./notification";
import checkout from "./checkout";

export default combineReducers({
	accountData: account,
	menuData: menu,
	appData: app,
	logData: log,
	authData: auth,
	checkoutData: checkout,
	contactData: contact,
	documentData,
	userData: user,
	locationData: location,
	notificationData: notification,
});
