import React, { useState } from "react";
import _get from "lodash/get";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";
import theme from "./styles/theme";
import MainNavigator from "./Navigator/MainNavigator";
import MainRoutes from "./Navigator/MainRoutes";
import { signOut, getProfile } from "./actions/auth/authActions";
import Header from "./containers/Header";
import { getAccountAuth } from "./actions/account/accountActions";
import * as cookie from "react-cookie";
import { Helmet } from "react-helmet";
const cookies = new cookie.Cookies();

const drawerWidth = 240;
const minDrawerWidth = 50;

const styles = {
    root: {
        display: "flex",
        minHeight: "100vh"
    },
    drawer: {
        [theme.breakpoints.up("lg")]: {
            width: drawerWidth,
            flexShrink: 0,
        },
        [theme.breakpoints.up("md")]: {
            width: 0
        }
    },
    appContent: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        background: "white",
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        },
        [theme.breakpoints.up("md")]: {
            width: "100%"
        },
        [theme.breakpoints.up("lg")]: {
            width: "80%"
        }
    }
};

function AppSecure(props) {
    const navigate = useNavigate()
    const [mobileOpen, setMobileOpen] = useState(false)
    const [show, setShow] = useState(true)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    };

    const handleUserIconClick = () => {
        props.signOut();
    };

    const handleAppAccount = async () => {
        const response = await props.getAccountAuth();
        cookies.set("token", response.token, {
            path: "/",
            maxAge: 43200
        });
        await props.getProfile();
        setTimeout(() => {
            navigate("/dashboard/accounts")
            window.location.reload();
        }, 100);
    };

    const {
        classes,
        accountBaseUrl,
        adminKey, profile, busy
    } = props;

    if (!profile && !busy) {
        navigate("/");
    }

    return (
        <div className={classes.root}>
            <Helmet>
                <title>Web Portal - TYC</title>
            </Helmet>
            <CssBaseline />
            <div className={classes.appContent}>
                <Header
                    adminKey={adminKey}
                    handleAppAccount={handleAppAccount}
                    onDrawerToggle={handleDrawerToggle}
                    handleUserIconClick={handleUserIconClick}
                />
                <div className="d-flex">
                    <nav className={classes.drawer} style={{ width: show ? drawerWidth : minDrawerWidth }}>
                        <Hidden lgUp implementation="js">
                            <MainNavigator
                                PaperProps={{
                                    style: { width: drawerWidth }
                                }}
                                variant="temporary"
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                show={true}
                            />
                        </Hidden>
                        <Hidden mdDown implementation="css">
                            <MainNavigator
                                show={show}
                                setShow={() => { setShow(!show) }}
                                PaperProps={{
                                    style: { width: show ? drawerWidth : minDrawerWidth }
                                }}
                            />
                        </Hidden>

                    </nav>
                    <MainRoutes accountBaseUrl={accountBaseUrl} />
                </div>
            </div>
        </div>
    );
}

AppSecure.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isDriver: _get(state, "authData.isDriver", false),
        accountBaseUrl: _get(state, "authData.accountBaseUrl"),
        adminKey: _get(state, "authData.profile.adminId"),
        profile: _get(state, "authData.profile"),
        needAutorization: _get(state, "authData.needAutorization", false),
        busy: _get(state, "appData.appBusy", true)
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            signOut,
            getAccountAuth,
            getProfile
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AppSecure)
);
