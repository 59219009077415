import React from "react";
import _get from "lodash/get";
import { alpha, withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({
  addButtonWrapper: {
    width: "99%",
    textAlign: "right",
    marginTop: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100px",
    textAlign: "left",
  },
  secondaryBar: {
    zIndex: 0,
  },
  button: {
    margin: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
});

function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    let context = this,
      args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function AutocompleteInput(props) {
  const { classes, delay = 300, minimum = 2 } = props;
  const {
    name,
    label,
    type = "text",
    placeholder,
    disabled = false,
    onBlur = () => {},
    md = 4,
    xs = 12,
    value,
    helpText = "",
    style = {},
    values,
  } = props;

  const onChange = debounce((e) => {
    props.onChange(e);
  }, delay);
  const defaultValue = _get(values, name);
  const options = props.options.map((_item) => _item.name);
  return (
    <Grid item md={md} xs={xs} style={style}>
      <Autocomplete
        id={name}
        name={name}
        options={options}
        getOptionLabel={(option) => option}
        style={{ width: "100%" }}
        defaultValue={defaultValue}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label={label || name} onChange={onChange} />
        )}
      />
    </Grid>
  );
}

export default withStyles(styles)(AutocompleteInput);
