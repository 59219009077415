import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import {
  formatDateTime,
  formatUtcDateTime,
  formatUtcDay,
} from "../../../utils/momentHelpers";
import { formatCurrency } from "../../../utils/numberHelpers";
import Error from "../../Error";
import Success from "../../Success";
import DeleteModal from "../DeleteModal";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    maxHeight: 200,
  },
  container: {
    // maxHeight: 440,
  },
});

function TbBillTableRow(props) {
  const classes = useStyles();
  const [delItem, setDelItem] = useState(null);
  const [added, setAdded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMsg] = useState("");
  const [successMessage, setSuccessMsg] = useState("");
  const data = props.data || {};

  async function save(params) {
    try {
      setLoading(true);

      const payload = {
        billId: params.bill_id,
      };
      const response = await props.saveTbBill(payload);
      if (response && response.error) {
        const msg =
          response.errorCode === "42703"
            ? "Bill is already added"
            : "Not able to add bill";
        setErrorMsg(msg);
      } else {
        props.afterSave();
        setAdded(true);
        setSuccessMsg("Bill is added successfully.");
      }
      setTimeout(() => {
        setErrorMsg("");
        setSuccessMsg("");
      }, 5000);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }
  const isAdded = !!data.billObj;

  return (
    <>
      {!!delItem && (
        <DeleteModal
          data={delItem}
          open={!!delItem}
          onDone={() => {
            setAdded(false);
            setDelItem(null);
            props.afterSave();
            data.billObj = null;
          }}
          deleteBill={props.deleteBill}
          handleClose={() => setDelItem(null)}
        />
      )}
      <TableRow key={data.bill_number}>
        <TableCell component="th" scope="row">
          {data.bill_number}
        </TableCell>
        <TableCell align="right">{data.order_number}</TableCell>
        <TableCell align="right">
          {formatCurrency(data.sales_revenue)}
        </TableCell>
        <TableCell align="right">{formatCurrency(data.tax_amount)}</TableCell>
        <TableCell align="right">
          {formatCurrency(data.payment_amount)}
        </TableCell>
        <TableCell align="right">
          {formatUtcDateTime(data.paid_at_local)}{" "}
          {formatUtcDay(data.paid_at_local)}
        </TableCell>
        <TableCell align="right">{data.waiter_name}</TableCell>
        <TableCell align="right">
          {errorMessage && <Error message={errorMessage} />}
          <Button
            variant="contained"
            color={isAdded || added ? "default" : "primary"}
            size="small"
            style={{ padding: 0 }}
            disabled={loading}
            onClick={() => (isAdded ? setDelItem(data.billObj) : save(data))}
          >
            {isAdded ? "DELETE" : loading ? "SAVING" : added ? "SAVED" : "SAVE"}
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function TbBillTable(props) {
  const classes = useStyles();
  const bills = props.bills || [];
  // console.log(bills[0]);

  return (
    <TableContainer component={Paper} style={{ maxHeight: 450 }}>
      <Table
        size={"small"}
        stickyHeader
        className={classes.table}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Bill#</TableCell>
            <TableCell align="right">Order#</TableCell>
            <TableCell align="right">Sales</TableCell>
            <TableCell align="right">Tax</TableCell>
            <TableCell align="right">Payment</TableCell>
            <TableCell align="right">Date</TableCell>
            <TableCell align="right">Waiter</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bills.map((data) => (
            <TbBillTableRow
              key={data.bill_number}
              data={data}
              saveTbBill={props.saveTbBill}
              afterSave={props.afterSave}
              deleteBill={props.deleteBill}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
