import _get from "lodash/get";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import RenderContact from "./RenderContact";
import RenderItems from "./RenderItems";
import RenderRate from "./RenderRate";
import { LOGO_LINE } from "../../../../utils/logos";

const styles = (theme) => ({});

export default function DetailPage(props) {
  const classes = props.classes;
  const catering = props.catering || {};
  const contact = catering.contact || {};
  const fullName = `${contact.firstName} ${contact.lastName}`;
  const phone = contact.phone ? `Ph: ${contact.phone}` : "";
  const email = contact.email ? `${contact.email}` : "";
  const seperator = contact.email && contact.phone ? " ," : "";
  const place = contact.place || "";

  return (
    <Grid container>
      <Grid item xs={12} style={{ textAlign: "center", marginTop: 30 }}>
      <img
        src={LOGO_LINE}
        alt={"The Yellow Chilli"}
        style={{ maxWidth: 150, maxHeight: 55, margin: "auto" }}
      />
        <Typography variant="h5">The Yellow Chilli</Typography>
        <Typography variant="subtitle1">Danville, CA-94526</Typography>
        <RenderContact catering={catering} />
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center", marginTop: 10 }}>
        <RenderItems catering={catering} />
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center", marginTop: 10 }}>
        <RenderRate catering={catering} />
      </Grid>
    </Grid>
  );
}
