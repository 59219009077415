// import _get from "lodash/get";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import MenuIcon from '@material-ui/icons/Menu';
import KitchenIcon from '@material-ui/icons/Kitchen';
import AppsIcon from "@material-ui/icons/Apps";
import DevicesIcon from "@material-ui/icons/Devices";
import PieChartIcon from "@material-ui/icons/PieChart";
import NavigatorLinks from "./NavigatorLinks";
import RestaurantIcon from '@material-ui/icons/Restaurant';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import EmailIcon from '@material-ui/icons/Email';
import MoneyIcon from '@material-ui/icons/Money';

const appRoutes = [
  {
    id: "Dashboard",
    label: "Dashboard",
    children: [
      {
        id: "Dashboard",
        label: "Dashboard",
        icon: <MenuIcon />,
        url: "/dashboard",
        admin: true,
      },
      {
        id: "Contact Us",
        label: "ContactUs",
        icon: <ContactSupportIcon />,
        url: "/contact",
        admin: true,
      },
      {
        id: "Leads",
        label: "Leads",
        icon: <EmailIcon />,
        url: "/leads",
        admin: true,
      },
      {
        id: "Catering",
        label: "Catering",
        icon: <KitchenIcon />,
        url: "/catering",
        admin: true,
      },
      {
          id: "TB Bills",
          label: "Tb Bills",
          icon: <AppsIcon />,
          url: "/bill",
          admin: true
      },
      {
        id: "Payment",
        label: "Payment",
        icon: <MoneyIcon />,
        url: "/payment",
        admin: true,
      },
      {
        id: "Menu Group",
        label: "Menu Group",
        icon: <MenuIcon />,
        url: "/menu-group",
        admin: true,
      },
      {
        id: "Menu Item",
        label: "Menu Item",
        icon: <MenuIcon />,
        url: "/menu-item",
        admin: true,
      },
      // {
      //     id: "Device",
      //     label: "Device",
      //     icon: <DevicesIcon />,
      //     url: "/devices",
      //     admin: true
      // },
      // {
      //   id: "Location",
      //   label: "Location",
      //   icon: <RestaurantIcon />,
      //   url: "/location",
      //   admin: true,
      // },
    ],
  },
];

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  headerLogo: {
    width: 55,
    margin: "auto",
  },
  item: {
    paddingTop: 8,
    paddingBottom: 8,
    margin: "4px 0",
    fontSize: 14,
    fontWeight: 400,
    color: "#41536e",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: "#f4f5f7",
    },
  },
  itemCategory: {
    backgroundColor: "#171F2A",
    boxShadow: "0 -1px 0 #404854 inset",
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 10,
    paddingRight: 10,
  },
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
  },
  itemActionable: {
    "&:hover": {
      backgroundColor: "#009ddd26",
    },
  },
  itemActiveItem: {
    color: "#009ddd",
    backgroundColor: "#009ddd26",
    position: "relative",
  },
  itemPrimary: {
    color: "inherit",
    fontSize: theme.typography.fontSize,
    "&$textDense": {
      fontSize: theme.typography.fontSize,
    },
  },
  textDense: {},
  divider: {
    backgroundColor: "#dfe1e6",
    height: 2,
    marginTop: 0,
  },
});

function MainNavigator(props) {
  const { classes, onClose, show, setShow, ...other } = props;

  return (
    <Drawer anchor="left" variant="permanent" {...other}>
      <List
        disablePadding
        className="sidebar-list"
        style={{ padding: show ? 16 : 0, marginBottom: 52 }}
      >
        <NavigatorLinks
          index={1}
          show={show}
          appRoutes={appRoutes}
          classes={classes}
          onClose={onClose}
        />
      </List>
      <div className="slide-toggle">
        <div className="slide-togglebar "></div>
        <div className="toggle-line">
          <span className="css-xwsnrl"></span>
        </div>
        <div>
          <button
            type="button"
            className={`toggle-btn ${show ? "toggle-btn-r1" : "toggle-btn-r2"}`}
            onClick={setShow}
          >
            <span className="css-pxzk9z">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                role="presentation"
              >
                <path
                  d="M10.294 9.698a.988.988 0 010-1.407 1.01 1.01 0 011.419 0l2.965 2.94a1.09 1.09 0 010 1.548l-2.955 2.93a1.01 1.01 0 01-1.42 0 .988.988 0 010-1.407l2.318-2.297-2.327-2.307z"
                  fill="currentColor"
                  fillRule="evenodd"
                ></path>
              </svg>
            </span>
            <div className="css-z8pkji"></div>
          </button>
        </div>
      </div>
    </Drawer>
  );
}

MainNavigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({});
export default withStyles(styles)(connect(mapStateToProps, {})(MainNavigator));
