// import _get from "lodash/get";
import { React, useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toolbar from "@material-ui/core/Toolbar";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { getLeads } from "../../actions/catering/cateringActions";

import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import LeadTable from "./LeadTable";
import CreateInquiry from "./CreateInquiry";
import AcceptInquiry from "./AcceptInquiry";

const styles = (theme) => ({
  paper: {
    margin: 15,
    overflow: "hidden",
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: "block",
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: "40px 16px",
  },
});

function Leads(props) {
  const { classes } = props;
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [inquiries, setInquiries] = useState([]);
  const [selected, setSelected] = useState({});
  // const [message, setMessage] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [isAccept, setIsAccept] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getLeads(skip, take);
    // eslint-disable-next-line
  }, []);

  async function getLeads(_skip, _take) {
    try {
      setLoading(true);
      const params = { skip: _skip, take: _take };
      let response = await props.getLeads(params);
      setInquiries(response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  // const validateEmail = (data) => {
  //   const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return re.test(String(data).toLowerCase());
  // };

  const onSubmit = () => {
    // if (validateEmail(email)) {
    //   setMessage("");
    //   getLeads();
    // } else {
    //   setMessage("Enter Valid Email");
    // }
  };

  const onAccept = (_item) => {
    setSelected(_item);
    setIsAccept(true);
  };

  return (
    <Paper className={classes.paper}>
      {isAdd && (
        <CreateInquiry
          open={isAdd}
          handleClose={() => {
            // setAddOrder(false);
          }}
          onClose={() => {
            // setAddOrder(false);
          }}
        />
      )}
      {isAccept && (
        <AcceptInquiry
          open={isAccept}
          data={selected}
          handleClose={() => {
            setIsAccept(false);
            getLeads();
          }}
        />
      )}

      <AppBar
        className={classes.searchBar}
        position="static"
        color="default"
        elevation={0}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <TextField
                fullWidth
                placeholder="Search"
                onChange={(e) => {
                  // setEmail(e.target.value);
                }}
                InputProps={{
                  disableUnderline: true,
                  className: classes.searchInput,
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSubmit();
                  }
                }}
              />
            </Grid>
            <Grid item>
              <IconButton aria-label="Search" onClick={onSubmit}>
                <SearchIcon className={classes.block} color="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        <div style={{ textAlign: "right" }}></div>

        {loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        )}
        <LeadTable
          skip={skip}
          take={take}
          items={inquiries}
          onAccept={onAccept}
          handleChangePage={(params) => {
            const _skip = params.skip;
            const _take = params.take;
            setSkip(_skip);
            setTake(_take);
            getLeads(_skip, _take);
          }}
        />
      </div>
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return {
    classes: PropTypes.object.isRequired,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getLeads,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Leads)
);
