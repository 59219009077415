import axios from "../axios";
import _get from "lodash/get";
import * as TYPES from "./contactActionTypes";

export const setContacts = (value) => ({
  type: TYPES.CONTACTS,
  value,
});

export const setContactsCount = (value) => ({
  type: TYPES.CONTACTS_COUNT,
  value,
});

export function getContactCount(params = {}) {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = _get(getState(), "appData.BASE_URL");
      params.locationId = _get(getState(), "authData.profile.locationId");

      const response = await axios({
        url: `${BASE_URL}/secure/contact/count`,
        method: "get",
        params,
      });
      const _response = _get(response, "data", 0);
      dispatch(setContactsCount(_response));
      return _response;
    } catch (error) {
      return {
        errorMsg: _get(error, "response.data.errors[0].message"),
      };
    }
  };
}

export function getContact(params = {}) {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = _get(getState(), "appData.BASE_URL");
      params.locationId = _get(getState(), "authData.profile.locationId");

      const response = await axios({
        url: `${BASE_URL}/secure/contact`,
        method: "get",
        params,
      });
      const _response = _get(response, "data", []);
      dispatch(setContacts(_response));
      return _response;
    } catch (error) {
      return {
        errorMsg: _get(error, "response.data.errors[0].message"),
      };
    }
  };
}
