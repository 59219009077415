import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Grid } from "@material-ui/core";

const styles = theme => ({
    root: {
        padding: "24px",
        width: "98%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: theme.spacing(1.5),
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    capitalize: {
        textTransform: "capitalize"
    },
    icon: {
        color: "#009be5",
        fontSize: 16,
        marginBottom: -2,
        width: 25
    },
    dataSmallRow: {
        minWidth: 90
    },
});

function AuditTable(props) {
    const { classes, data = {}, } = props;
    const address = data.address || {}
    const contact = data.contact || []
    return (
        <React.Fragment>
            <Paper className={classes.root}>
                <Grid container>
                    <Grid item md={3}>Name:</Grid>
                    <Grid item md={3}>{data.name} </Grid>
                    <Grid item md={3}>Address: </Grid>
                    <Grid item md={3}>{address.line1}, {address.city}</Grid>
                </Grid>
                <Grid container>
                    {contact.map((c, i) => {
                        return <Grid item md={6} key={i}>
                            <Grid container>
                                <Grid item md={6} style={{ textTransform: "capitalize" }}>{c.key.toLowerCase()}: </Grid>
                                <Grid item md={6}>{c.value} </Grid>
                            </Grid>
                        </Grid>
                    })}
                </Grid>
            </Paper>
        </React.Fragment>
    );
}

AuditTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AuditTable);
