import { React, useState, useEffect } from "react";
import _get from "lodash/get";
import { useParams } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Form, Formik, Field } from "formik";
import InputBox from "../../../../components/InputBox";
import SelectBox from "../../../../components/SelectBox";
import Error from "../../../Error";
import Success from "../../../Success";
import { validate, initialValues } from "./validate";
import {
  getMenusItems,
  getMenusGroups,
} from "../../../../actions/menu/menuActions";
import { updateCateringItem } from "../../../../actions/catering/cateringActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { STATUS, COURSES, SALE_TYPE, RESTRICIONS } from "../../../../constants";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  AlignCenter: {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-evenly",
  },
  AlignBtn: {
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  agriExm: {
    border: "1px solid grey",
    textTransform: "uppercase",
    flexBasis: "25%",
    flexShrink: 0,
    padding: 4,
    backgroundColor: "white",
    textAlign: "center",
    color: "black",
    display: "inline-block",
    width: 110,
    borderRadius: 5,
    fontWeight: 500,
  },
});

function GroupItemModal(props) {
  const selectedCur = (props.selectedItem && props.selectedItem.items) || [];
  // const selectedCur = _groupitems.map((ee) => parseInt(ee.id));
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setError] = useState("");
  const [successMessage, setSuccess] = useState("");
  const [items, setItems] = useState([]);
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState("ALL");
  const [selected, setSelected] = useState(selectedCur);

  const { classes, data } = props;
  const isSmallScreen = window.innerWidth < 500;

  async function getItems() {
    try {
      const response = await props.getMenusItems({
        skip: 0,
        take: 500,
        isGroup: true,
        isCatering: true,
      });
      // console.log("response ::: ", response);
      setItems(response);
    } catch (e) {
      console.log("e :: ", e);
    }
  }
  async function getGroupItem(groupId) {
    try {
      let response = await props.getMenusGroups({ id: groupId });
      // console.log("response :: ", response);
      if (response && response.id) {
        const _items = response.groupitems || [];
        // console.log("_items :: ", _items);
        const __items = _items.map((_itemInfo) => _itemInfo.item);
        // console.log("__items :: ", __items);
        setItems(__items);
      }
      // setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  async function getGroups() {
    try {
      let response = await props.getMenusGroups();
      if (response && Array.isArray(response)) {
        let _groups = [{ name: "ALL", id: "ALL" }];

        for (let i = 0; i < response.length; i++) {
          _groups.push(response[i]);
        }
        setGroups(_groups);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  useEffect(() => {
    getItems();
    // eslint-disable-next-line
    getGroups();
    // eslint-disable-next-line
  }, []);

  const save = async () => {
    let params = props.selectedItem;

    params.items = selected.map((_item) => ({
      id: _item.id,
      name: _item.name,
      qty: _item.qty,
      note: _item.note,
    }));

    try {
      setSaving(true);

      const response = await props.updateCateringItem(params);

      setSaving(false);
      if (response.id) {
        setSuccess("Updated successfully");
        setTimeout(() => props.handleClose(), 1000);
      } else {
        setError("Please try after sometime.");
      }
    } catch (e) {
      console.log(e);
    }
  };

  async function updateChange(event, item) {
    const checked = event.target.checked;
    if (checked) {
      let _selected = [...selected];
      _selected.push(item);
      setSelected(_selected);
    } else {
      let _selected = selected.filter(
        (_item) => parseInt(_item.id) !== parseInt(item.id)
      );
      setSelected(_selected);
    }
  }

  return (
    <Dialog
      fullScreen={isSmallScreen}
      fullWidth={true}
      maxWidth={"md"}
      className={classes.wrapper}
      open={!!props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" onClose={props.handleClose}>
        <Typography>{"Choose Items"}</Typography>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={props.handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={{
            ...initialValues,
            ...data,
            groupId: "ALL",
          }}
          validate={validate}
          onSubmit={save}
        >
          {({ values, handleSubmit, setFieldValue }) => {
            return (
              <Form style={{ padding: "20px 10px" }}>
                <Grid container spacing={2}>
                  <Error top={20} message={errorMessage} />
                  <Success top={20} message={successMessage} />
                </Grid>
                <Grid item md={12} xs={12} style={{ padding: "25px 0px" }}>
                  <SelectBox
                    items={groups}
                    md={12}
                    name={`groupId`}
                    label="Group"
                    onChange={(e) => {
                      console.log(" VALUE :: ", e.target.value);
                      const _id = e.target.value;
                      if (_id === "ALL") {
                        getItems();
                      } else {
                        getGroupItem(_id);
                      }
                    }}
                  />
                </Grid>
                {items &&
                  items.map((item, index) => {
                    const isSelected = selected.find(
                      (_item) => parseInt(_item.id) === parseInt(item.id)
                    );
                    return (
                      <Grid container spacing={2} key={`${item.id}`}>
                        <Grid item md={4} xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="isCatering"
                                disabled={saving}
                                onChange={(e) => updateChange(e, item)}
                                checked={!!isSelected}
                                inputProps={{
                                  "aria-labelledby": "is-Catering-up",
                                }}
                              />
                            }
                            label={item.name}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}

                {successMessage && <Success message={successMessage} />}
                {errorMessage && <Error message={errorMessage} />}

                <Grid
                  item
                  md={12}
                  xs={12}
                  style={{
                    marginTop: 15,
                    textAlign: "center",
                  }}
                >
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={save}
                  >
                    {"SAVE"}
                  </Button>
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                  style={{
                    marginTop: 15,
                    textAlign: "center",
                  }}
                >
                  <Button onClick={props.handleClose}>{"Close"}</Button>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMenusItems,
      getMenusGroups,
      updateCateringItem,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(GroupItemModal)
);
