import axios from "../axios";
import _get from "lodash/get";
import * as TYPES from "./billsActionTypes";
import * as cookie from "react-cookie";
const cookies = new cookie.Cookies();

// const venueId = "39165";
// const key =
//   "s%3AhA8zaBqFmbierwuqjkZiXdUeOQC1vhpq.b4cMdFZKm2Q3dBYpUx6B6LxthVBhhxPtbFFBLPB3Olo";

function getTbKey() {
  return cookies.get("tbKey", {
    path: "/",
  });
}

function getTbVenueId() {
  return cookies.get("tbVenueId", {
    path: "/",
  });
}

export const setBills = (value) => ({
  type: TYPES.BILLS,
  value,
});

export function getBills(params = {}) {
  return async (dispatch, getState) => {
    try {
      params.venueId = getTbVenueId();

      const BASE_URL = _get(getState(), "appData.BASE_URL");
      const response = await axios({
        url: `${BASE_URL}/secure/bill`,
        method: "get",
        params,
      });
      const _response = _get(response, "data", []);

      return _response;
    } catch (error) {
      return null;
    }
  };
}

export function deleteBill(params = {}) {
  return async (dispatch, getState) => {
    try {
      params.key = getTbKey();
      params.venueId = getTbVenueId();

      const BASE_URL = _get(getState(), "appData.BASE_URL");
      const response = await axios({
        url: `${BASE_URL}/secure/bill`,
        method: "delete",
        params,
      });
      const _response = _get(response, "data", {});

      return _response;
    } catch (error) {
      return null;
    }
  };
}

export function getTbBills(params = {}) {
  return async (dispatch, getState) => {
    try {
      if (params.key) {
        cookies.set("tbKey", params.key, {
          path: "/",
          maxAge: 43200,
        });
      } else {
        params.key = getTbKey();
      }

      if (params.venueId) {
        cookies.set("tbVenueId", params.venueId, {
          path: "/",
          maxAge: 43200,
        });
      } else {
        params.venueId = getTbVenueId();
      }

      const BASE_URL = _get(getState(), "appData.BASE_URL");
      const response = await axios({
        url: `${BASE_URL}/secure/bill/tb`,
        method: "get",
        params,
      });
      const _response = _get(response, "data", []);

      return _response;
    } catch (error) {
      return null;
    }
  };
}

export function saveTbBill(params = {}) {
  return async (dispatch, getState) => {
    try {
      params.key = getTbKey();
      params.venueId = getTbVenueId();

      const BASE_URL = _get(getState(), "appData.BASE_URL");
      const response = await axios({
        url: `${BASE_URL}/secure/bill/tb`,
        method: "post",
        data: params,
      });
      const _response = _get(response, "data", {});

      return _response;
    } catch (error) {
      return null;
    }
  };
}
