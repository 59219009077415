import React from "react";
import Typography from "@material-ui/core/Typography";
import { formatCateringItems } from "../../../../utils/menuHelpers";
import { CATERING_MAPPING } from "../../../../utils/constants";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

function RenderItem(props) {
  const item = props.item || {};
  const name = item.name || "";
  const _items = item.items || [];
  const items = _items.sort((a, b) => a.index - b.index);
  return (
    <Grid item xs={12} md={4}>
      <div style={{ marginTop: 25, textTransform: "capitalize" }}>
        <Typography variant="subtitle1">
          {`${name}:- `}
          <IconButton
            aria-label="Edit"
            size="small"
            onClick={() => props.onItemsEdit(item)}
          >
            <EditIcon color={"primary"} />
          </IconButton>
        </Typography>
        {items.map((item, idx) => (
          <Typography key={`key-${idx}`} variant="body2">
            {`${item.name}`}
            {idx === items.length - 1 ? `` : ", "}
          </Typography>
        ))}
      </div>
    </Grid>
  );
}

export default function ItemEditPage(props) {
  const catering = props.catering || {};
  const _items = catering.items || [];
  const items = _items.sort((a, b) => a.index - b.index);
  // console.log('_items :: ', _items);
  return (
    <Grid container spacing={1}>
      {items.map((item) => (
        <RenderItem
          key={`${item.id}`}
          item={item}
          onItemsEdit={props.onItemsEdit}
        />
      ))}
    </Grid>
  );
}
