export const initialValues = {
  name: "",
  type: "FOOD",
  status: "ACTIVE",
  isPickup: false,
  isCatering: false,
  isDineIn: false,
};

export const validate = (values) => {
  const errors = {};
  const requiredFields = ["name", "status"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = `Required`;
    }
  });

  return errors;
};
