import { React, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {
  getMenusItems,
  getMenusGroups,
} from "../../../actions/menu/menuActions";
import { CircularProgress } from "@material-ui/core";
import AddMenuItem from "./AddMenuItem";
import { useSearchParams } from "react-router-dom";
import MenuItemTable from "./MenuItemTable";

const styles = (theme) => ({
  paper: {
    margin: 15,
    overflow: "hidden",
  },
  title: {
    flexGrow: 1,
  },
});

function MenuItem(props) {
  const { classes } = props;

  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [items, setItems] = useState([]);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selGroup, setSelGroup] = useState("");
  const [selMenu, setSelMenu] = useState("");

  const [searchParams] = useSearchParams();

  useEffect(() => {
    getItems(skip, take);
    getGroups();
    // eslint-disable-next-line
  }, []);

  async function getItems(_skip, _take) {
    try {
      setLoading(true);

      let response = await props.getMenusItems({ skip: _skip, take: _take });
      if (response.length > 0) {
        setItems(response);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }
  async function getGroups() {
    try {
      let response = await props.getMenusGroups();
      if (response.length > 0) {
        setGroups(response);
      }
    } catch (e) {}
  }

  return (
    <>
      {isEdit && (
        <AddMenuItem
          open={isEdit}
          handleClose={() => {
            setIsEdit(false);
            getItems();
          }}
        />
      )}

      <Card className={classes.root}>
        <CardContent>
          <Grid container className={classes.root} spacing={2}>
            <Grid item md={12} xs={12}>
              <AppBar position="static">
                <Toolbar>
                  <Typography
                    variant="h6"
                    className={classes.title}
                  ></Typography>
                  <div style={{ marginRight: 12 }}>
                    <select
                      md={3}
                      name="menuId"
                      label="Group"
                      value={selGroup}
                      style={{ padding: 8, marginLeft: 4 }}
                      onChange={(e) => {
                        getItems(e.target.value);
                        setSelGroup(e.target.value);
                      }}
                    >
                      <option>Group</option>
                      {groups.map((e, i) => {
                        return (
                          <option key={i} value={e._id}>
                            {e.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setIsEdit(true)}
                  >
                    ADD
                  </Button>
                </Toolbar>
              </AppBar>
            </Grid>
            <Grid item md={12} xs={12}>
              <MenuItemTable
                skip={skip}
                take={take}
                items={items}
                handleChangePage={(params) => {
                  const _skip = params.skip;
                  const _take = params.take;
                  setSkip(_skip);
                  setTake(_take);
                  getItems(_skip, _take);
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMenusItems,
      getMenusGroups,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MenuItem)
);
