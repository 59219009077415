export const initialValues = {
  userId: "",
  locationId: "",
  menuId: "",
  name: "",
  course: "",
  type: "",
  status: "ACTIVE",
  restriction: "NO_LIQUOR",
};
export const validate = (values) => {
  const errors = {};
  const requiredFields = ["name", "course", "type", "restriction"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = `Required`;
    }
  });
  return errors;
};
