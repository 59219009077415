import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { formatCateringItems } from "../../../../utils/menuHelpers";
import { CATERING_MAPPING } from "../../../../utils/constants";

function RenderItem(props) {
  const item = props.item || {};

  const name = item.name || "";
  const qty = item.qty || "";
  const rate = item.rate || "";
  const type = item.type || "";
  const value = item.total || "";

  return (
    <Grid container>
      <Grid item xs={8} style={{ textAlign: "right", paddingRight: 5 }}>
        <Typography variant="body2">{name}</Typography>
      </Grid>
      <Grid item xs={4} style={{ textAlign: "left" }}>
        <Typography variant="body2">{value}</Typography>
      </Grid>
    </Grid>
  );
}

export default function RenderRate(props) {
  const catering = props.catering || {};
  const amount = catering.amount || {};
  const rate = catering.rate || [];
  // console.log(" amount :: ", amount);
  // console.log(" rate :: ", rate);
  return (
    <div style={{ marginTop: 45, maxWidth: 250, margin: "auto" }}>
      {rate.map((item, idx) => (
        <RenderItem item={item} key={`key-${idx}`} />
      ))}
      <RenderItem name="Food (100*$90.00) = " value="$9000.00" />
      <RenderItem name="Drinks (100*$5.00) = " value="$500.00" />
      <RenderItem name="Banquet = " value="$500.00" />
      <RenderItem name="Service charge (20%)= " value="$500.00" />
    </div>
  );
}
