import React, { useEffect, useState } from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { bindActionCreators } from "redux";
import { Formik, Form } from "formik";
import package_ from "../../../package.json";
import InputBox from "../../components/InputBox";
import {
    signIn,
    getProfile,
    signOut,
    pushTokenUpdate
} from "../../actions/auth/authActions";
import validate from "./validate";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Error from "../Error";
import ForgetDialog from "./ForgetDialog";
import Typography from "@material-ui/core/Typography";
import LOGO_BB from "../../utils/icons/logo.png";
import { withStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

function LogIn(props) {
    const [signInError, setSignInError] = useState("")
    const [loading, setLoading] = useState(false)
    const [isForgetDialog, setIsForgetDialog] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
    }, [])

    // const updateFirebaseToken = async () => {
    //     try {
    //         if (isSupported()) {
    //             const fcmWebToken = await getToken();
    //             if (fcmWebToken) {
    //                 // const response =
    //                 await props.pushTokenUpdate({
    //                     fcmWebToken,
    //                     platform: "WEB"
    //                 });
    //             }
    //         } else {
    //             console.log("fMessaging :: ", "not supported");
    //         }
    //     } catch (e) {
    //         console.log("setFirebaseToken :: ", e);
    //     }
    // };

    const logIn = async values => {
        setSignInError("")
        setLoading(true)
        const response = await props.signIn(values);

        const isToken = response && response.access_token;
        // const isTerms = response && response.user && !response.user.terms;

        // if (isToken && isTerms) {
        //     return this.renderDialog();
        // } else
        if (isToken) {
            // await this.updateFirebaseToken();
            sucessLogin();
        } else {
            setSignInError("Invalid email or password")
            setLoading(false)
        }
    };

    const sucessLogin = () => {
        // const {
        // accountBaseUrl,
        // isAdmin,
        // isManager,
        // isDriver,
        // driverId
        // } = props;

        navigate("/dashboard")
        // props.history.push(`/orders`);

        // if (isManager && accountBaseUrl) {
        //     this.props.history.push(`${accountBaseUrl}/status/view`);
        // } else if (isAdmin && accountBaseUrl) {
        //     this.props.history.push(`/orders`);
        // } else if (isDriver && accountBaseUrl) {
        //     this.props.history.push(
        //         `${accountBaseUrl}/status/diary/${driverId}`
        //     );
        // } else {
        //     this.setState({ signInError: "Invalid email or password", loading: false });
        // }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Login - TYC</title>
                {/* <meta name="description" content="Smartelds ELD is FMCSA Registered ELD Solution. If you are looking to use Smartelds ELD in your fleet business, then you are on the right page. For Login contact us today!" /> */}
            </Helmet>
            {isForgetDialog && (
                <ForgetDialog
                    open={isForgetDialog}
                    onClose={() => setIsForgetDialog(false)}
                />
            )}
            {/* {token && userId && (
                <UpdatePasswordDialog
                    token={token}
                    userId={userId}
                    open={!!(token && userId)}
                    onClose={() => {
                        // setToken(null)
                        setUserId(null)
                    }}
                />
            )} */}
            <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                alignContent={"center"}
                style={{ marginTop: "10vh" }}
            >
                <Grid item xs={10} sm={4}>
                    <Paper style={{ padding: "50px 25px" }}>
                        <Formik
                            initialValues={{ username: "", password: "" }}
                            validate={validate}
                            onSubmit={logIn}
                            enableReinitialize={true}
                        >
                            {({ values, handleSubmit, setFieldValue }) => {
                                return (
                                    <Form style={{ padding: "20px 10px" }}>
                                        <Grid container spacing={3}>
                                            <Grid
                                                item
                                                md={12}
                                                style={{
                                                    textAlign: "center"
                                                }}
                                            >
                                                <img
                                                    src={LOGO_BB}
                                                    alt={"Logo"}
                                                    style={{
                                                        maxWidth: 250
                                                    }}
                                                />
                                            </Grid>
                                            <InputBox
                                                md={12}
                                                name="username"
                                                label="Email"
                                            />
                                            <InputBox
                                                md={12}
                                                type="password"
                                                name="password"
                                                label="Password"
                                            />
                                        </Grid>
                                        {signInError && (
                                            <Error message={signInError} />
                                        )}

                                        <Grid
                                            item
                                            md={12}
                                            xs={12}
                                            style={{
                                                marginTop: 45,
                                                textAlign: "center"
                                            }}
                                        >
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={loading}
                                                style={{
                                                    minWidth: 250
                                                }}
                                            >
                                                {loading
                                                    ? "Loading..."
                                                    : "Log In"}
                                            </Button>
                                        </Grid>
                                        <Grid
                                            item
                                            md={12}
                                            xs={12}
                                            style={{
                                                marginTop: 15,
                                                textAlign: "center"
                                            }}
                                        >
                                            <Button
                                                style={{
                                                    color: "#009be5"
                                                }}
                                                onClick={() =>
                                                    setIsForgetDialog(true)
                                                }
                                            >
                                                {"Forget password?"}
                                            </Button>
                                        </Grid>
                                        <Grid
                                            style={{ textAlign: "center" }}
                                        >
                                            <Typography
                                                className=""
                                                color="textSecondary"
                                                gutterBottom
                                            >
                                                <small>{package_.version}</small>
                                            </Typography>
                                        </Grid>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        state,
        signInError: _get(state, "authData.signInError", ""),
        isDriver: _get(state, "authData.isDriver", false),
        isAdmin: _get(state, "authData.isAdmin", false),
        isManager: _get(state, "authData.isManager", false),
        driverId: _get(state, "authData.driverId", ""),
        loading: _get(state, "appData.appBusy", false),
        logInValues: _get(state, "form.logInForm.values", {}),
        allErrors: _get(state, "form.logInForm.syncErrors", {}),
        allFields: _get(state, "form.logInForm.fields", {}),
        accountBaseUrl: _get(state, "authData.accountBaseUrl")
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            signIn,
            getProfile,
            signOut,
            pushTokenUpdate
        },
        dispatch
    );

export default withStyles({})(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(LogIn)
);
