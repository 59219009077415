import React, { useEffect, useState } from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Error from "../Error";
import Success from "../Success";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

function DeleteModal(props) {
  const [loading, setLoading] = useState(false);
  const [bills, setBills] = useState(false);
  const isSmallScreen = window.innerWidth < 500;

  const deleteBill = async () => {
    try {
      setLoading(true);
      let data = props.data || {};
      const params = { id: data.id };
      const response = await props.deleteBill(params);
      setLoading(false);

      setTimeout(() => {
        props.onDone();
      }, 1000);
    } catch (e) {
      console.log(e);
    }
  };

  const { classes, constants, data } = props;

  return (
    <Dialog
      fullScreen={isSmallScreen}
      fullWidth={true}
      maxWidth={"md"}
      className={classes.wrapper}
      open={!!props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" onClose={props.handleClose}>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={props.handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div style={{ textAlign: "center", padding: 25 }}>
          <h1>Do you really want to delete Bill# {data.bill_number} ?</h1>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            size="small"
            style={{ marginTop: 12, minWidth: 125 }}
            onClick={deleteBill}
          >
            {loading ? "Deleting..." : "DELETE"}
          </Button>
          <br />
          <Button
            color="primary"
            disabled={loading}
            size="small"
            style={{ marginTop: 12, minWidth: 125 }}
            onClick={props.handleClose}
          >
            {"CLOSE"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default withStyles(styles)(DeleteModal);
