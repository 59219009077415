// import _get from "lodash/get";
import { React, useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toolbar from "@material-ui/core/Toolbar";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import BillsTable from "./BillsTable";
import DeleteModal from "./DeleteModal";
import { getBills, deleteBill } from "../../actions/bills/billsActions";

const styles = (theme) => ({
  paper: {
    margin: 15,
    overflow: "hidden",
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: "block",
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: "40px 16px",
  },
});

function Bills(props) {
  const { classes } = props;
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(1000);
  const [bills, setBills] = useState([]);
  const [selected, setSelected] = useState({});
  // const [message, setMessage] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [delItem, setDelItem] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    _getBills(skip, take);
    // eslint-disable-next-line
  }, []);

  async function _getBills(_skip, _take, start, end) {
    try {
      setLoading(true);
      let params = { skip: _skip, take: _take };

      if (start) {
        params.start = start;
      }
      if (end) {
        params.end = end;
      }
      const response = await props.getBills(params);
      setBills(response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const onDelDone = () => {
    setDelItem(null);
    _getBills();
  };

  const onDelete = (_item) => {
    setDelItem(_item);
  };

  return (
    <Paper className={classes.paper}>
      <div className={classes.contentWrapper}>
        <div style={{ textAlign: "right" }}></div>
        {!!delItem && (
          <DeleteModal
            data={delItem}
            open={!!delItem}
            onDone={onDelDone}
            deleteBill={props.deleteBill}
            handleClose={() => setDelItem(null)}
          />
        )}
        {loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        )}
        <BillsTable
          skip={skip}
          take={take}
          items={bills}
          onDelete={onDelete}
          handleChangePage={(params) => {
            const _skip = params.skip;
            const _take = params.take;
            const start = params.start;
            const end = params.end;
            setSkip(_skip);
            setTake(_take);
            _getBills(_skip, _take, start, end);
          }}
        />
      </div>
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return {
    classes: PropTypes.object.isRequired,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getBills,
      deleteBill,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Bills)
);
