import axios from "./axios";
import _get from "lodash/get";

export function getLocation(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");

    params.locationId = _get(getState(), "authData.profile.locationId");

    return axios({
      url: `${BASE_URL}/secure/location`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", []);
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}
