import React, { useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LogIn from "./containers/LogIn";
import AppSecure from "./AppSecure";
import { onMessageListener } from "./firebase";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";

function App(props) {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState({});

    const handleClose = event => {
        setOpen(false);
    };

    onMessageListener()
        .then(payload => {
            setMessage(payload.notification);
            setOpen(true);
        })
        .catch(err => console.log("failed: ", err));

    return (<Router>
        <div>
            <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleClose}
                    severity="warning"
                >
                    <AlertTitle>{message.title}</AlertTitle>
                    {message.body}
                </Alert>
            </Snackbar>
            {props.preCheck ?  <Routes>
                <Route exact path="/" element={<LogIn />} />
                <Route path="*" element={<AppSecure />} />
            </Routes> :
            <Routes>
                <Route exact path="/" component={LogIn} />
            </Routes>
            }
        </div>
    </Router>
    );
}

const mapStateToProps = state => ({
    preCheck: state.appData.preCheck,
    profile: state.authData.prifile
});

export default connect(
    mapStateToProps,
    {}
)(App);
