export const initialValues = {
  name: "",
  slug: "",
  stock: 9999,
  type: "FOOD",
  status: "ACTIVE",
  isPickup: false,
  isCatering: false,
  isDineIn: false,
  description: "",
};

export const validate = (values) => {
  const errors = {};
  const requiredFields = ["name", "description"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = `Required`;
    }
  });

  return errors;
};
