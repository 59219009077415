import React from "react";
import Typography from "@material-ui/core/Typography";
import { formatCateringItems } from "../../../../utils/menuHelpers";
import { CATERING_MAPPING } from "../../../../utils/constants";

function RenderItem(props) {
  const item = props.item || {};
  const name = item.name || "";
  const _items = item.items || [];
  const items = _items.sort((a, b) => a.index - b.index);
  return (
    <div style={{ marginTop: 25, textTransform: "capitalize" }}>
      <Typography variant="h6">{name}</Typography>
      {items.map((item, idx) => (
        <Typography key={`key-${idx}`} variant="body1">
          {item.name}
        </Typography>
      ))}
    </div>
  );
}

export default function RenderItems(props) {
  const catering = props.catering || {};
  const _items = catering.items || [];
  const items = _items.sort((a, b) => a.index - b.index);

  return (
    <div>
      {items.map((item) => (
        <RenderItem key={`${item.id}`} item={item} />
      ))}
    </div>
  );
}
