// import _get from "lodash/get";
import { React, useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toolbar from "@material-ui/core/Toolbar";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CateringTable from "./CateringTable";
import CreateCatering from "./CreateCatering";
import { getCaterings } from "../../actions/catering/cateringActions";

const styles = (theme) => ({
  paper: {
    margin: 15,
    overflow: "hidden",
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: "block",
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: "40px 16px",
  },
});

function Catering(props) {
  const { classes } = props;
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [caterings, setCaterings] = useState([]);
  // const [email, setEmail] = useState("");
  // const [message, setMessage] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCaterings();
    // eslint-disable-next-line
  }, []);

  async function getCaterings() {
    setLoading(true);
    let response = await props.getCaterings();
    setCaterings(response);
    setLoading(false);
  }

  // const validateEmail = (data) => {
  //   const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return re.test(String(data).toLowerCase());
  // };

  const onSubmit = () => {
    // if (validateEmail(email)) {
    //   setMessage("");
    //   getOrders();
    // } else {
    //   setMessage("Enter Valid Email");
    // }
  };

  return (
    <Paper className={classes.paper}>
      {isAdd && (
        <CreateCatering
          open={isAdd}
          handleClose={() => {
            // setAddOrder(false);
          }}
          onClose={() => {
            // setAddOrder(false);
          }}
        />
      )}

      <AppBar
        className={classes.searchBar}
        position="static"
        color="default"
        elevation={0}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <TextField
                fullWidth
                placeholder="Search"
                onChange={(e) => {
                  // setEmail(e.target.value);
                }}
                InputProps={{
                  disableUnderline: true,
                  className: classes.searchInput,
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSubmit();
                  }
                }}
              />
            </Grid>
            <Grid item>
              <IconButton aria-label="Search" onClick={onSubmit}>
                <SearchIcon className={classes.block} color="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        <div style={{ textAlign: "right" }}></div>

        {loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        )}
        <CateringTable
          skip={skip}
          take={take}
          items={caterings}
          handleChangePage={(params) => {
            const _skip = params.skip;
            const _take = params.take;
            setSkip(_skip);
            setTake(_take);
            setCaterings(_skip, _take);
          }}
        />
      </div>
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return {
    classes: PropTypes.object.isRequired,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCaterings,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Catering)
);
