import React, { useEffect, useState } from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import { getTimeZone } from "../../actions/momentActions";
import Grid from "@material-ui/core/Grid";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import Error from "../Error";
import Success from "../Success";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { addDevice } from "../../actions/deviceActions";
import { getUsers } from "../../actions/userActions";
import validate from "./validate";
import {
  PAYMENT_CATEGORY,
  PAYMENT_MODE,
  PAYMENT_TYPE_IN,
  PAYMENT_TYPE_OUT,
} from "../../constants";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

function AddDevice(props) {
  const [errorMessage, setError] = useState("");
  const [successMessage, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const isSmallScreen = window.innerWidth < 500;
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getAllUsers({ skip: 0, limit: 250 });
    // eslint-disable-next-line
  }, []);

  async function getAllUsers() {
    let response = await props.getUsers();
    setUsers(response);
  }

  const save = async (params) => {
    setLoading(true);
    let response;
    if (!params._id) {
      response = await props.addDevice(params);
    } else {
      params = { payload: params, id: params._id };
      response = await props.addDevice(params);
    }
    if (response._id) {
      setLoading(false);
      setSuccess("Order created successfully");
      setTimeout(() => props.onClose(), 2000);
    } else {
      setLoading(false);
      setError("Please try after sometime.");
    }
  };

  const { classes } = props;

  const initialValues = {
    category: "IN",
    mode: "CASH",
    type: "CATERING",
  };

  return (
    <Dialog
      fullScreen={isSmallScreen}
      fullWidth={true}
      maxWidth={"md"}
      className={classes.wrapper}
      open={!!props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" onClose={props.handleClose}>
        <Typography>Add Payment</Typography>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={props.handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={save}
          enableReinitialize={true}
        >
          {({ values, handleSubmit, setFieldValue }) => {
            const isIn = values.category === "IN";
            const PAYMENT_TYPE = isIn ? PAYMENT_TYPE_IN : PAYMENT_TYPE_OUT;
            const LABEL = isIn ? "From" : "To";
            return (
              <Form style={{ padding: "20px 10px" }}>
                <Grid container spacing={3}>
                  <SelectBox
                    md={4}
                    name="category"
                    label="Category"
                    items={PAYMENT_CATEGORY}
                  />
                  <SelectBox
                    md={4}
                    name="mode"
                    label="Mode"
                    items={PAYMENT_MODE}
                  />
                  <SelectBox
                    md={4}
                    name="type"
                    label="Type"
                    items={PAYMENT_TYPE}
                  />
                  <InputBox name="title" label="Title" md={4} />
                  <InputBox name="amount" label="Amount" md={4} />
                  <InputBox name="date" label="Date" md={4} />
                  <InputBox name="notes" label={"Notes"} md={12} />
                  <Grid md="12">
                    <div
                      style={{
                        marginLeft: 10,
                        marginTop: 20,
                        fontSize: 22,
                      }}
                    >
                      {isIn ? "Payment recieved from" : "Payment given to"}
                    </div>
                  </Grid>
                  <InputBox name="name" label={`Name`} md={4} />
                  <InputBox name="email" label={`Email`} md={4} />
                  <InputBox name="phone" label={`Phone`} md={4} />
                </Grid>
                {successMessage && <Success message={successMessage} />}
                {errorMessage && <Error message={errorMessage} />}
                <div
                  style={{
                    textAlign: "right",
                    marginTop: 25,
                    marginBottom: 25,
                  }}
                >
                  <Button
                    color="primary"
                    disabled={loading}
                    onClick={props.handleClose}
                  >
                    {"CANCEL"}
                  </Button>{" "}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                  >
                    {loading ? "Saving..." : "SAVE"}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "equipmentData.error", ""),
    loading: _get(state, "accountData.accountCreateBusy", false),
    accountValues: _get(state, "form.accountForm.values", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUsers,
      addDevice,
      getTimeZone,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AddDevice)
);
