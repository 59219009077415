import axios from "./axios";
import _get from "lodash/get";
import { fmcsaBusy } from "./orderActions";

export function getDevices(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const locationId = _get(getState(), "authData.profile.locationId[0]");
        return axios({
            url: `${BASE_URL}/secure/location/device?locationId=${locationId}`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", []);
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                return response;
            });
    };
}


export function addDevice(data) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");

        return axios({
            url: `${BASE_URL}/secure/admin/location/device`,
            method: "post",
            data
        })
            .then(response => {
                const _response = _get(response, "data");
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(fmcsaBusy(false));
                return response;
            });
    };
}