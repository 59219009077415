import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core/styles";
import { useSearchParams, useParams } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {
  getMenusItems,
  getMenusGroups,
} from "../../../../actions/menu/menuActions";
import { withStyles } from "@material-ui/core/styles";
import EditModal from "./EditModal";
import MenuGroupItems from "./MenuGroupItems";

const styles = {
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  saveButton: {
    float: "right",
    marginRight: 5,
  },
};

function MenuGroupDetail(props) {
  const { classes } = props;
  const [item, setItem] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  const bull = <span className={classes.bullet}>•</span>;

  let params = useParams();

  async function getItem() {
    try {
      setLoading(true);

      let response = await props.getMenusGroups({ id: params.groupId });
      if (response && response.id) {
        setItem(response);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  useEffect(() => {
    getItem();
    // eslint-disable-next-line
  }, []);

  const isCatering = item.isCatering || false;
  const isDineIn = item.isDineIn || false;
  const isPickup = item.isPickup || false;

  return (
    <>
      <Card className={classes.root}>
        {isEdit && (
          <EditModal
            data={item}
            open={isEdit}
            handleClose={() => {
              getItem();
              setIsEdit(false);
            }}
          />
        )}
        <CardContent>
          <Grid container className={classes.root} spacing={2}>
            <Grid item md={12} xs={12}>
              <Typography variant="h5" component="h2">
                {item.name}
                <Button
                  className={classes.saveButton}
                  color="primary"
                  variant="contained"
                  size={"small"}
                  onClick={() => setIsEdit(true)}
                >
                  {"EDIT"}
                </Button>
              </Typography>

              <Typography variant="body2" component="p">
                {`Course ${item.course}, ${item.status}, ${item.restriction}`}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <List dense className={classes.root}>
                <ListItem button>
                  <ListItemText id={`is-catering`} primary={`Catering`} />
                  <ListItemSecondaryAction>
                    {isCatering ? "ON" : "OFF"}
                  </ListItemSecondaryAction>
                </ListItem>

                <ListItem button>
                  <ListItemText id={`is-dine-in`} primary={`Dine In`} />
                  <ListItemSecondaryAction>
                    {isDineIn ? "ON" : "OFF"}
                  </ListItemSecondaryAction>
                </ListItem>

                <ListItem button>
                  <ListItemText id={`id-is-pick-up`} primary={`Pick Up`} />
                  <ListItemSecondaryAction>
                    {isPickup ? "ON" : "OFF"}
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <MenuGroupItems />
    </>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMenusItems,
      getMenusGroups,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MenuGroupDetail)
);
