import { React, useState, useEffect } from "react";
import _get from "lodash/get";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Form, Formik, Field } from "formik";
import InputBox from "../../../../../components/InputBox";
import SelectBox from "../../../../../components/SelectBox";
import AutocompleteInput from "../../../../../components/AutocompleteInput";
import Error from "../../../../Error";
import Success from "../../../../Success";
import { validate, initialValues } from "./validate";
import { getMenusItems } from "../../../../../actions/menu/menuActions";
import { updateCateringItem } from "../../../../../actions/catering/cateringActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  CATERING_FORMULA,
  CATERING_RATE_TYPES,
} from "../../../../../utils/constants";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function EditRateModal(props) {
  const classes = useStyles();
  const selected = props.selected || {};
  const [saving, setSaving] = useState(false);
  const [errorMessage, setError] = useState("");
  const [successMessage, setSuccess] = useState("");

  const isSmallScreen = window.innerWidth < 500;

  function save(values) {
    let _rates = props.rates || [];
    console.log("values :::: ", values);
    if (values.index) {
      values.index = _rates.length + 1;
      _rates.push(values);
    } else {
      values.index = _rates.length + 1;
      _rates.push(values);
    }
    props.onSave(_rates);
  }

  function updateAmount(index, rate, fm, qty, rt) {}

  return (
    <Dialog
      fullScreen={isSmallScreen}
      fullWidth={true}
      maxWidth={"md"}
      className={classes.wrapper}
      open={!!props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="rate-edit-dialog" onClose={props.handleClose}>
        <Typography>
          {"UPDATE RATE"}
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={props.handleClose}
            style={{ float: "right" }}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={{
            ...initialValues,
            ...selected,
          }}
          validate={validate}
          onSubmit={save}
        >
          {({ values, handleSubmit, setFieldValue }) => {
            const fm = values.fm;
            const rate = values.rate;
            const qty = values.qty;

            const noQty = ["ADD", "SUB", "P_A"];
            if (noQty.includes(fm)) {
              values.qty = "0";
              values.total = rate;
            } else if (fm === "M") {
              values.total = parseFloat(rate) * parseFloat(qty);
            }

            return (
              <Form style={{ padding: "20px 10px" }}>
                <Grid container spacing={2}>
                  <AutocompleteInput
                    options={CATERING_RATE_TYPES}
                    md={3}
                    name={`name`}
                    label="Name"
                    values={values}
                    onChange={(e) => {
                      setFieldValue(`name`, e.target.value);
                    }}
                  />
                  <SelectBox
                    md={2}
                    name={`fm`}
                    label="Formula"
                    items={CATERING_FORMULA}
                    onChange={(e) => {
                      // const r = values.rate;
                      // const f = e.target.value;
                      // const q = values.qty;
                      // const t = updateAmount(r, f, q);
                    }}
                  />

                  <InputBox
                    md={2}
                    name={`rate`}
                    label="Rate"
                    onChange={(e) => {
                      // const r = e.target.value;
                      // const f = values.fm;
                      // const q = values.qty;
                      // const t = updateAmount(r, f, q);
                    }}
                  />
                  {(fm === "M" || fm === "M_S") && (
                    <InputBox
                      md={2}
                      name={`qty`}
                      label="Quantity"
                      onChange={(e) => {
                        // const r = values.rate;
                        // const f = values.fm;
                        // const q = e.target.value;
                        // const t = updateAmount(r, f, q);
                      }}
                    />
                  )}
                  <InputBox md={3} name={`total`} label="Total" />

                  {successMessage && <Success message={successMessage} />}
                  {errorMessage && <Error message={errorMessage} />}

                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{
                      marginTop: 15,
                      textAlign: "center",
                    }}
                  >
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      type="submit"
                    >
                      {"SAVE"}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{
                      marginTop: 15,
                      textAlign: "center",
                    }}
                  >
                    <Button onClick={props.handleClose}>{"Close"}</Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default EditRateModal;
