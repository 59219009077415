import React, { useState } from "react";
import _get from "lodash/get";
import _remove from "lodash/remove";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import EditRateModal from "./EditRateModal";
import { formatCurrency } from "../../../../../utils/numberHelpers";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function RateInfoEdit(props) {
  // catering
  const classes = useStyles();
  const _rates = _get(props, "catering.rate", []);
  const [isEdit, setIsEdit] = useState(false);
  const [selected, setSelected] = useState(null);
  const [rates, setRates] = useState(_rates);

  // console.log("rates :: ", rates);

  function onAdd() {
    setSelected();
    setIsEdit(true);
  }

  function onEdit(_selected) {
    setSelected(_selected);
    setIsEdit(true);
  }

  function onDelete(_selected) {
    const __rates = _remove(rates, (n) => {
      return n.index !== _selected.index;
    });
    setRates(__rates);
  }

  function handleClose() {
    setSelected(null);
    setIsEdit(false);
  }

  function handleSave(_rates) {
    setSelected(null);
    setIsEdit(false);
    setRates(_rates);
  }

  return (
    <>
      {isEdit && (
        <EditRateModal
          open={isEdit}
          selected={selected}
          handleClose={handleClose}
          rates={rates}
          onSave={handleSave}
        />
      )}
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableBody>
            {rates.map((item, idx) => (
              <TableRow key={`${item.name}-${idx}`}>
                <TableCell component="th" scope="row">
                  {item.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {formatCurrency(item.rate)}
                  {` X `}
                  {item.qty}
                </TableCell>

                <TableCell align="right">
                  {formatCurrency(item.total)}
                  <IconButton aria-label="Edit" onClick={() => onEdit(item)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label="Delete"
                    onClick={() => onDelete(item)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <IconButton aria-label="Add" onClick={() => onAdd()}>
        <AddIcon />
      </IconButton>
    </>
  );
}
