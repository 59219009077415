const defaultState = {
    accountBusy: false,
    accountAuthBusy: false,
    accountCreateBusy: false,
    accounts: [],
    selectedAccount: {},
    selectedAccountData: {}
};

export default function carrier(state = defaultState, action) {
    switch (action.type) {
        case "ACCOUNTS":
            return {
                ...state,
                accounts: action.value
            };
        case "SELECTED_ACCOUNT":
            return {
                ...state,
                selectedAccount: action.value
            };
        case "SELECTED_ACCOUNT_DATA":
            return {
                ...state,
                selectedAccountData: action.value
            };
        case "ACCOUNTS_BUSY":
            return {
                ...state,
                accountBusy: action.value
            };
        case "ACCOUNTS_CREATE_BUSY":
            return {
                ...state,
                accountCreateBusy: action.value
            };
        case "ACCOUNTS_AUTH_BUSY":
            return {
                ...state,
                accountAuthBusy: action.value
            };
        default:
            return state;
    }
}
