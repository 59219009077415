export const CATERING_GROUPS = [
  "DRINK",
  "APPETIZER",
  "MAIN_COURSE",
  "DESERT",
  "LIVE_STATION",
];

export const CATERING_MAPPING = {
  DRINK: "Drinks",
  APPETIZER: "Appetizers",
  MAIN_COURSE: "Main Course",
  DESERT: "DESERT",
  LIVE_STATION: "Live Station",
  OTHER: "Other",
};

export const DATES_SEARCH = [
  { id: "thisMonth", name: "This Month" },
  { id: "lastMonth", name: "Last Month" },
  { id: "custom", name: "Custom" },
];

export const CATERING_FORMULA = [
  { id: "M", name: "Multiply" },
  { id: "ADD", name: "ADD" },
  { id: "SUB", name: "SUB" },
  { id: "M_S", name: "Multiply(Sub)" },
  { id: "P_A", name: "Percent(All)" },
];

export const CATERING_RATE_TYPES = [
  { id: "Food", name: "Food" },
  { id: "Drink", name: "Drink" },
  { id: "Banquet", name: "Banquet" },
  { id: "Service Charge", name: "Service Charge" },
  { id: "Tax", name: "Tax" },
  { id: "Delivery", name: "Delivery" },
];
