import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Routes } from "react-router-dom";

import Dashboard from "../containers/Dashboard";
import Location from "../containers/Location";
import Device from "../containers/Device";

import Catering from "../containers/Catering";
import CateringDetail from "../containers/Catering/CateringDetail";

import Leads from "../containers/Leads";

import Contact from "../containers/Contact";

import Bills from "../containers/Bills";
import Payment from "../containers/Payment";

import Menu from "../containers/Menu";
import MenuDetail from "../containers/Menu/MenuDetail";
import MenuGroup from "../containers/Menu/MenuGroup";
import MenuGroupDetail from "../containers/Menu/MenuGroup/MenuGroupDetail";

import MenuItem from "../containers/Menu/MenuItem";
import MenuItemDetail from "../containers/Menu/MenuItemDetail";

const styles = {
  mainContent: {
    flex: 1,
    padding: "0px",
  },
};

function MainRoutes(props) {
  const { classes } = props;

  return (
    <main className={classes.mainContent}>
      <Routes>
        <Route exact path={`/dashboard`} element={<Dashboard />} />
        <Route exact path={`/contact`} element={<Contact />} />

        <Route exact path={`/leads`} element={<Leads />} />
        <Route exact path={`/catering`} element={<Catering />} />
        <Route exact path={`/catering/:cateringId`} element={<CateringDetail />} />

        <Route exact path={`/bill`} element={<Bills />} />
        <Route exact path={`/payment`} element={<Payment />} />

        <Route exact path={`/menu`} element={<Menu />} />
        <Route exact path={`/menu/:menuId`} element={<MenuDetail />} />

        <Route exact path={`/menu-group`} element={<MenuGroup />} />
        <Route exact path={`/menu-group/:groupId`} element={<MenuGroupDetail />} />

        <Route exact path={`/menu-item`} element={<MenuItem />} />
        <Route exact path={`/menu-item/:itemId`} element={<MenuItemDetail />} />
        <Route exact path={`/location`} element={<Location />} />
        <Route exact path={`/devices`} element={<Device />} />
      </Routes>
    </main>
  );
}

MainRoutes.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MainRoutes);
