export const MASKS = {
  minHrMask: [/[0-9]/, /[0-9]/, ":", /[0-6]/, /[0-9]/],
  minHrAMPMMask: [/[0-1]/, /\d/, ":", /[0-6]/, /\d/, " ", /[a,p,A,P]/, /[m,M]/],
  phoneUSMask: [
    "(",
    /[2-9]/,
    /[0-9]/,
    /[0-9]/,
    ")",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    "-",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ],
};

export const SUFFIX = [
  {
    id: "Jr",
    name: "Jr",
  },
  {
    id: "II",
    name: "II",
  },
  {
    id: "Sr",
    name: "Sr",
  },
  {
    id: "III",
    name: "II",
  },
  {
    id: "IV",
    name: "IV",
  },
  {
    id: "V",
    name: "V",
  },
];

export const TIME_ZONES = [
  {
    id: "America/New_York",
    name: "Eastern Standard Time (EST)",
  },
  {
    id: "America/Chicago",
    name: "Central Standard Time (CST)",
  },
  {
    id: "America/Denver",
    name: "Mountain Standard Time (MST)",
  },
  {
    id: "America/Los_Angeles",
    name: "Pacific Standard Time (PST)",
  },
];

export const ALL_OPTIONS = [
  {
    name: "ON",
    id: "ON",
  },
  {
    name: "OFF",
    id: "OFF",
  },
];

export const ENABLE_DISABLE = [
  {
    name: "ENABLE",
    id: true,
  },
  {
    name: "DISABLE",
    id: false,
  },
];

export const API_STATUS = [
  {
    name: "ACTIVE",
    id: true,
  },
  {
    name: "INACTIVE",
    id: false,
  },
];

export const STATUS = [
  {
    name: "Active",
    id: "ACTIVE",
  },
  {
    name: "Inactive",
    id: "INACTIVE",
  },
];

export const ALL_STATUS_OPTIONS = [
  {
    name: "Active",
    id: "ACTIVE",
  },
  {
    name: "Inactive",
    id: "IN_ACTIVE",
  },
  {
    name: "Delete",
    id: "DELETE",
  },
];

export const ALL_ACCOUNT_STATUS_OPTIONS = [
  {
    name: "Active",
    id: "ACTIVE",
  },
  {
    name: "On Hold",
    id: "ON_HOLD",
  },
  {
    name: "Inactive",
    id: "IN_ACTIVE",
  },
  {
    name: "Suspended",
    id: "SUSPENDED",
  },
  {
    name: "Delete",
    id: "DELETE",
  },
];

export const US_STATES = [
  {
    name: "Alabama",
    id: "AL",
    country: "US",
  },
  {
    name: "Alaska",
    id: "AK",
    country: "US",
  },
  {
    name: "American Samoa",
    id: "AS",
    country: "US",
  },
  {
    name: "Arizona",
    id: "AZ",
    country: "US",
  },
  {
    name: "Arkansas",
    id: "AR",
    country: "US",
  },
  {
    name: "California",
    id: "CA",
    country: "US",
  },
  {
    name: "Colorado",
    id: "CO",
    country: "US",
  },
  {
    name: "Connecticut",
    id: "CT",
    country: "US",
  },
  {
    name: "Delaware",
    id: "DE",
    country: "US",
  },
  {
    name: "District Of Columbia",
    id: "DC",
    country: "US",
  },
  {
    name: "Federated States Of Micronesia",
    id: "FM",
    country: "US",
  },
  {
    name: "Florida",
    id: "FL",
    country: "US",
  },
  {
    name: "Georgia",
    id: "GA",
    country: "US",
  },
  {
    name: "Guam",
    id: "GU",
    country: "US",
  },
  {
    name: "Hawaii",
    id: "HI",
    country: "US",
  },
  {
    name: "Idaho",
    id: "ID",
    country: "US",
  },
  {
    name: "Illinois",
    id: "IL",
    country: "US",
  },
  {
    name: "Indiana",
    id: "IN",
    country: "US",
  },
  {
    name: "Iowa",
    id: "IA",
    country: "US",
  },
  {
    name: "Kansas",
    id: "KS",
    country: "US",
  },
  {
    name: "Kentucky",
    id: "KY",
    country: "US",
  },
  {
    name: "Louisiana",
    id: "LA",
    country: "US",
  },
  {
    name: "Maine",
    id: "ME",
    country: "US",
  },
  {
    name: "Marshall Islands",
    id: "MH",
    country: "US",
  },
  {
    name: "Maryland",
    id: "MD",
    country: "US",
  },
  {
    name: "Massachusetts",
    id: "MA",
    country: "US",
  },
  {
    name: "Michigan",
    id: "MI",
    country: "US",
  },
  {
    name: "Minnesota",
    id: "MN",
    country: "US",
  },
  {
    name: "Mississippi",
    id: "MS",
    country: "US",
  },
  {
    name: "Missouri",
    id: "MO",
    country: "US",
  },
  {
    name: "Montana",
    id: "MT",
    country: "US",
  },
  {
    name: "Nebraska",
    id: "NE",
    country: "US",
  },
  {
    name: "Nevada",
    id: "NV",
    country: "US",
  },
  {
    name: "New Hampshire",
    id: "NH",
    country: "US",
  },
  {
    name: "New Jersey",
    id: "NJ",
    country: "US",
  },
  {
    name: "New Mexico",
    id: "NM",
    country: "US",
  },
  {
    name: "New York",
    id: "NY",
    country: "US",
  },
  {
    name: "North Carolina",
    id: "NC",
    country: "US",
  },
  {
    name: "North Dakota",
    id: "ND",
    country: "US",
  },
  {
    name: "Northern Mariana Islands",
    id: "MP",
    country: "US",
  },
  {
    name: "Ohio",
    id: "OH",
    country: "US",
  },
  {
    name: "Oklahoma",
    id: "OK",
    country: "US",
  },
  {
    name: "Oregon",
    id: "OR",
    country: "US",
  },
  {
    name: "Palau",
    id: "PW",
    country: "US",
  },
  {
    name: "Pennsylvania",
    id: "PA",
    country: "US",
  },
  {
    name: "Puerto Rico",
    id: "PR",
    country: "US",
  },
  {
    name: "Rhode Island",
    id: "RI",
    country: "US",
  },
  {
    name: "South Carolina",
    id: "SC",
    country: "US",
  },
  {
    name: "South Dakota",
    id: "SD",
    country: "US",
  },
  {
    name: "Tennessee",
    id: "TN",
    country: "US",
  },
  {
    name: "Texas",
    id: "TX",
    country: "US",
  },
  {
    name: "Utah",
    id: "UT",
    country: "US",
  },
  {
    name: "Vermont",
    id: "VT",
    country: "US",
  },
  {
    name: "Virgin Islands",
    id: "VI",
    country: "US",
  },
  {
    name: "Virginia",
    id: "VA",
    country: "US",
  },
  {
    name: "Washington",
    id: "WA",
    country: "US",
  },
  {
    name: "West Virginia",
    id: "WV",
    country: "US",
  },
  {
    name: "Wisconsin",
    id: "WI",
    country: "US",
  },
  {
    name: "Wyoming",
    id: "WY",
    country: "US",
  },
];

export const CA_STATES = [
  {
    id: "AB",
    name: "Alberta",
    country: "CA",
  },
  {
    id: "BC",
    name: "British Columbia",
    country: "CA",
  },
  {
    id: "MB",
    name: "Manitoba",
    country: "CA",
  },
  {
    id: "NB",
    name: "New Brunswick",
    country: "CA",
  },
  {
    id: "NL",
    name: "Newfoundland and Labrador",
    country: "CA",
    alt: ["Newfoundland", "Labrador"],
  },
  {
    id: "NS",
    name: "Nova Scotia",
    country: "CA",
  },
  {
    id: "NU",
    name: "Nunavut",
    country: "CA",
  },
  {
    id: "NT",
    name: "Northwest Territories",
    country: "CA",
  },
  {
    id: "ON",
    name: "Ontario",
    country: "CA",
  },
  {
    id: "PE",
    name: "Prince Edward Island",
    country: "CA",
  },
  {
    id: "QC",
    name: "Quebec",
    country: "CA",
  },
  {
    id: "SK",
    name: "Saskatchewan",
    country: "CA",
  },
  {
    id: "YT",
    name: "Yukon",
    country: "CA",
  },
];

export const ALL_STATES = [...US_STATES, ...CA_STATES];

export const RATEBY = [{ id: "UNIT", name: "Unit" }];

export const COURSES = [
  { id: "1", name: "1" },
  { id: "2", name: "2" },
  { id: "3", name: "3" },
  { id: "4", name: "4" },
  { id: "5", name: "5" },
];

export const SALE_TYPE = [
  { id: "FOOD", name: "FOOD" },
  { id: "DRINK", name: "DRINK" },
];

export const RESTRICIONS = [{ id: "NO_LIQUOR", name: "NO_LIQUOR" }];

export const ACCEPT_OPTIONS = [
  { id: "NEW", name: "New" },
  { id: "IN_PROGRESS", name: "InProgress" },
  { id: "CONFIRM", name: "Confirm" },
];

export const ONSITE_OFFSITE = [
  { id: "CATERING", name: "Catering" },
  { id: "BANQUET", name: "Banquet" },
];

export const MENU_TYPES = [
  { id: "PREFIX2023", name: "Prefix2023" },
];

export const PAYMENT_CATEGORY = [
  { id: "IN", name: "In" },
  { id: "OUT", name: "Out" },
];

export const PAYMENT_MODE = [
  { id: "CHECK", name: "Check" },
  { id: "CASH", name: "Cash" },
];

export const PAYMENT_TYPE_IN = [
  { id: "CATERING", name: "Catering" },
  { id: "BANQUET", name: "Banquet" },
  { id: "DINEIN", name: "Dine In" },
  { id: "PICKUP", name: "Pick Up" },
  { id: "TIP", name: "Tip" },
  { id: "DELIVERY", name: "Dilivery" },
  { id: "CHECKCASH", name: "Check Cash" },
  { id: "OTHER", name: "Other" }
];

export const PAYMENT_TYPE_OUT = [
  { id: "EXPENSE", name: "Expense" },
  { id: "PAYOUT", name: "Pay out" },
  { id: "ADVANCE", name: "Advance" },
  { id: "TIP", name: "Tip" },
  { id: "SALARY", name: "Salary" },
  { id: "SALARYCASH", name: "Salary Cash" },
  { id: "SALARYCHECK", name: "Salary Check" },
  { id: "OTHER", name: "Other" }
];
