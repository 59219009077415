import React, { useEffect, useState } from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import { getTimeZone } from "../../actions/momentActions";
import Grid from "@material-ui/core/Grid";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import Error from "../Error";
import Success from "../Success";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { updateLead } from "../../actions/catering/cateringActions";
import validate from "./validate";
import { ACCEPT_OPTIONS, ONSITE_OFFSITE, MENU_TYPES } from "../../constants";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

function AcceptInquiry(props) {
  const [errorMessage, setError] = useState("");
  const [successMessage, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const isSmallScreen = window.innerWidth < 500;

  const save = async (params) => {
    setLoading(true);

    const response = await props.updateLead(params);

    if (response.id) {
      setLoading(false);
      setSuccess("Saved successfully");
      setTimeout(() => props.handleClose(), 1000);
    } else {
      setLoading(false);
      setError("Please try after sometime.");
    }
  };

  const { classes, constants } = props;

  function createCatering() {
    console.log("createCatering :::::::::::::::::::: ");
  }

  return (
    <Dialog
      fullScreen={isSmallScreen}
      fullWidth={true}
      maxWidth={"md"}
      className={classes.wrapper}
      open={!!props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" onClose={props.handleClose}>
        <Typography>{"Accept/Reject Inquiry"}</Typography>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={props.handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={props.data}
          onSubmit={save}
          enableReinitialize={true}
        >
          {({ values, handleSubmit, setFieldValue }) => {
            return (
              <Form style={{ padding: "20px 10px" }}>
                <Grid container spacing={3}>
                  <SelectBox
                    md={4}
                    name="status"
                    label="Status"
                    items={ACCEPT_OPTIONS}
                  />
                  <SelectBox
                    md={4}
                    name="type"
                    label="LOCATION"
                    items={ONSITE_OFFSITE}
                  />
                  <SelectBox
                    md={4}
                    name="menu"
                    label="Menu"
                    items={MENU_TYPES}
                  />
                  <Grid item md={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isEmail"
                          onChange={(e) =>
                            setFieldValue("isEmail", e.target.checked)
                          }
                        />
                      }
                      label={"Email menu"}
                    />
                  </Grid>
                </Grid>
                {successMessage && <Success message={successMessage} />}
                {errorMessage && <Error message={errorMessage} />}
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    style={{ marginTop: 12, minWidth: 125 }}
                  >
                    {loading ? "Saving..." : "UPDATE"}
                  </Button>{" "}
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    style={{ marginTop: 12, minWidth: 125 }}
                    onClick={createCatering}
                  >
                    {loading ? "Saving..." : "CONFIRMED"}
                  </Button>
                  <br />
                  <Button
                    color="primary"
                    disabled={loading}
                    style={{ marginTop: 12 }}
                    onClick={props.handleClose}
                  >
                    {"CANCEL"}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "equipmentData.error", ""),
    loading: _get(state, "accountData.accountCreateBusy", false),
    accountValues: _get(state, "form.accountForm.values", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateLead,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AcceptInquiry)
);
