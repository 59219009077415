import { React, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { getDevices } from "../../actions/deviceActions";
import { formatDateTime } from "../../actions/momentActions";
import PaymentTable from "./paymentTable";
import { Button } from "@material-ui/core";
import AddPayment from "./AddPayment";

const styles = theme => ({
    paper: {
        margin: 15,
        overflow: "hidden"
    },
    searchBar: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
    },
    searchInput: {
        fontSize: theme.typography.fontSize
    },
    block: {
        display: "block"
    },
    addUser: {
        marginRight: theme.spacing(1)
    },
    contentWrapper: {
        margin: "40px 16px"
    }
});

function Content(props) {
    const { classes } = props;
    const [events, setEvents] = useState([]);
    const [addPayment, setAddPayment] = useState(false)
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        getAllDayEvents()
        // eslint-disable-next-line
    }, [])

    async function getAllDayEvents() {
        setLoading(true)
        // let response = await props.getDevices();
        setEvents([])
        setLoading(false)
    }


    return (
        <Paper className={classes.paper}>
            {addPayment ? (
                <AddPayment
                    open={addPayment}
                    handleClose={() => {
                        setAddPayment(false)
                    }}
                    onClose={() => {
                        setAddPayment(false)
                    }}

                />) : ""}

            <div style={{ textAlign: "right" }}><Button type="button"
                variant="contained"
                color="primary"
                style={{ marginTop: 12, marginRight: 12 }}
                onClick={() => {
                    setAddPayment(true)
                }}>Add Cash</Button>
            </div>
            <div className={classes.contentWrapper}>
                {loading ? (
                    <div style={{ textAlign: "center" }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <PaymentTable
                            items={events}
                            formatDateTime={props.formatDateTime}
                        />
                    </>
                )}
            </div>
        </Paper>
    );
}

const mapStateToProps = state => {
    return {
        classes: PropTypes.object.isRequired
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getDevices,
            formatDateTime
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Content)
);
