import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const styles = theme => ({
    root: {
        width: "98%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: theme.spacing(1.5),
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    capitalize: {
        textTransform: "capitalize"
    },
    icon: {
        color: "#009be5",
        fontSize: 16,
        marginBottom: -2,
        width: 25
    },
    dataSmallRow: {
        minWidth: 90
    },
});

function ReduxTableRow(props) {
    const { classes } = props;
    let items = _get(props, "item", {});
    return (
        <TableRow hover>
            <TableCell component="th" scope="row" >
                <div className={classes.dataSmallRow}>
                    {items.deviceName || ""}
                </div>
            </TableCell>
            <TableCell>
                <div className={classes.dataSmallRow}>
                    {items.detial?.name}
                </div>
            </TableCell>
            <TableCell>
                <div className={classes.dataSmallRow}>
                    {items.status}
                </div>
            </TableCell>
        </TableRow >
    );
}

function AuditTable(props) {
    const { eld, classes, items = [], } = props;
    let allItems = []
    Object.values(items).forEach(e => {
        if (Array.isArray(e)) {
            allItems = [...allItems, ...e]
        } else {
            allItems = [...allItems, e]
        }
    })
    return (
        <React.Fragment>
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Device Name</TableCell>
                            <TableCell>Details</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allItems.map((items, i) => (
                            <ReduxTableRow
                                item={items}
                                key={i}
                                eld={eld}
                                classes={classes}
                                formatDateTime={props.formatDateTime}
                            />
                        ))}
                    </TableBody>
                </Table>
                {/* <NotFoundTableRow items={items} /> */}
                {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                        component="div"
                        count={count}
                        rowsPerPage={limit}
                        page={page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    /> */}
            </Paper>
        </React.Fragment>
    );
}

AuditTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AuditTable);
