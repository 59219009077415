import _get from "lodash/get";
import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core/styles";
import { useSearchParams, useParams } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {
  getMenusItems,
  getMenusGroups,
} from "../../../../actions/menu/menuActions";
import { withStyles } from "@material-ui/core/styles";
import GroupItemModal from "./GroupItemModal";

const styles = {
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  saveButton: {
    float: "right",
    marginRight: 5,
  },
};

function MenuGroupDetail(props) {
  const { classes } = props;
  const [group, setGroup] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  // const _groupitems = (props.data && props.data.groupitems) || [];

  const bull = <span className={classes.bullet}>•</span>;

  let params = useParams();

  async function getGroup() {
    try {
      setLoading(true);

      let response = await props.getMenusGroups({ id: params.groupId });

      if (response && response.id) {
        setGroup(response);

        const _items = response.groupitems || [];
        setItems(_items);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  useEffect(() => {
    getGroup();
    // eslint-disable-next-line
  }, []);

  const isCatering = group.isCatering || false;
  const isDineIn = group.isDineIn || false;
  const isPickup = group.isPickup || false;

  return (
    <Card className={classes.root}>
      {isEdit && (
        <GroupItemModal
          data={group}
          open={isEdit}
          handleClose={() => {
            getGroup();
            setIsEdit(false);
          }}
        />
      )}
      <CardContent>
        <Grid container className={classes.root} spacing={2}>
          <Grid item md={12} xs={12}>
            <Typography variant="h5" component="h2">
              {"Menu Items"}
            </Typography>
            <Typography variant="h5" component="h2">
              <Button
                color="primary"
                size={"small"}
                onClick={() => setIsEdit(true)}
              >
                {"Choose Items"}
              </Button>
            </Typography>

            <List component="nav" aria-label="main mailbox folders">
              {items.map((_item) => {
                const _name = _get(_item, "item.name", "");
                const _price = _get(_item, "item.price.amount", "");
                return (
                  <ListItem key={_item.id}>
                    <ListItemText
                      primary={`${_name}`}
                      secondary={`$${_price}`}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMenusItems,
      getMenusGroups,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MenuGroupDetail)
);
