import { createStore, compose, applyMiddleware } from "redux";
import rootReducer from "./actions/reducers";
import thunk from "redux-thunk";
import queryString from "query-string";
import loggerMiddleware from "./middlewares/loggerMiddleware";
import * as constants from "./constants";

import { getProfile } from "./actions/auth/authActions";

const moment = require("moment-timezone");

moment.createFromInputFallback = function (config) {
    config._d = new Date(config._i);
};

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let token;
try {
    const values = queryString.parse(window.location.search);
    token = values.token;
} catch (e) { }

const initialState = {
    appData: {
        preCheck: false,
        timezone: moment.tz.guess(),
        defaultTimezone: moment.tz.guess(),
        BASE_URL: process.env.REACT_APP_CORE_API,
        constants,
    }
};

const store = createStore(
    rootReducer,
    initialState,
    composeEnhancer(applyMiddleware(thunk, loggerMiddleware))
);

if (!token) {
    store.dispatch(getProfile());
}

export default store;
