import * as TYPES from "./contactActionTypes";
const defaultState = {
  contacts: [],
  count: 0,
};

export default function contact(state = defaultState, action) {
  switch (action.type) {
    case TYPES.CONTACTS:
      return {
        ...state,
        contacts: action.value,
      };
    case TYPES.CONTACTS_COUNT:
      return {
        ...state,
        count: action.value,
      };
    default:
      return state;
  }
}
