import axios from "../axios";
import _get from "lodash/get";
import * as TYPES from "./cateringActionTypes";

export const setInquiries = (value) => ({
  type: TYPES.INQUIRIES,
  value,
});

export const setCateringCount = (value) => ({
  type: TYPES.CATERINGS_COUNT,
  value,
});

export const setCaterings = (value) => ({
  type: TYPES.CATERINGS,
  value,
});

export function getLeads(params = {}) {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = _get(getState(), "appData.BASE_URL");
      params.locationId = _get(getState(), "authData.profile.locationId");

      const response = await axios({
        url: `${BASE_URL}/secure/catering/lead`,
        method: "get",
        params,
      });
      const _response = _get(response, "data", []);

      return _response;
    } catch (error) {
      return {
        errorMsg: _get(error, "response.data.errors[0].message")
      }
    }
  };
}

export function updateLead(params = {}) {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = _get(getState(), "appData.BASE_URL");
      params.locationId = _get(getState(), "authData.profile.locationId");

      const response = await axios({
        url: `${BASE_URL}/secure/catering/lead`,
        method: "put",
        data:params,
      });
      const _response = _get(response, "data", {});

      return _response;
    } catch (error) {
      return {
        errorMsg: _get(error, "response.data.errors[0].message")
      }
    }
  };
}

export function getInquiries(params = {}) {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = _get(getState(), "appData.BASE_URL");
      params.locationId = _get(getState(), "authData.profile.locationId");

      const response = await axios({
        url: `${BASE_URL}/secure/catering/inquiry`,
        method: "get",
        params,
      });
      const _response = _get(response, "data", []);
      dispatch(setInquiries(true));

      return _response;
    } catch (error) {
      return {
        errorMsg: _get(error, "response.data.errors[0].message")
      }
    }
  };
}

export function createInquiry(params = {}) {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = _get(getState(), "appData.BASE_URL");
      params.locationId = _get(getState(), "authData.profile.locationId");

      const response = await axios({
        url: `${BASE_URL}/secure/catering/inquiry`,
        method: "post",
        data:params,
      });
      const _response = _get(response, "data", {});

      return _response;
    } catch (error) {
      return {
        errorMsg: _get(error, "response.data.errors[0].message")
      }
    }
  };
}

export function updateInquiry(params = {}) {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = _get(getState(), "appData.BASE_URL");
      params.locationId = _get(getState(), "authData.profile.locationId");

      const response = await axios({
        url: `${BASE_URL}/secure/catering/inquiry`,
        method: "put",
        data:params,
      });
      const _response = _get(response, "data", {});

      return _response;
    } catch (error) {
      return {
        errorMsg: _get(error, "response.data.errors[0].message")
      }
    }
  };
}

export function getCateringsCount(params = {}) {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = _get(getState(), "appData.BASE_URL");
      params.locationId = _get(getState(), "authData.profile.locationId");

      const response = await axios({
        url: `${BASE_URL}/secure/catering/count`,
        method: "get",
        params,
      });
      const _response = _get(response, "data", 0);
      dispatch(setCateringCount(_response));

      return _response;
    } catch (error) {
      return {
        errorMsg: _get(error, "response.data.errors[0].message")
      }
    }
  };
}

export function getCaterings(params = {}) {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = _get(getState(), "appData.BASE_URL");
      params.locationId = _get(getState(), "authData.profile.locationId");

      const response = await axios({
        url: `${BASE_URL}/secure/catering`,
        method: "get",
        params,
      });
      const _response = _get(response, "data", []);
      dispatch(setCaterings(_response));

      return _response;
    } catch (error) {
      return {
        errorMsg: _get(error, "response.data.errors[0].message")
      }
    }
  };
}

export function createCaterings(data = {}) {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = _get(getState(), "appData.BASE_URL");
      data.locationId = _get(getState(), "authData.profile.locationId");

      const response = await axios({
        url: `${BASE_URL}/secure/catering`,
        method: "post",
        data,
      });
      const _response = _get(response, "data", {});

      return _response;
    } catch (error) {
      return {
        errorMsg: _get(error, "response.data.errors[0].message")
      }
    }
  };
}

export function updateCaterings(data = {}) {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = _get(getState(), "appData.BASE_URL");
      data.locationId = _get(getState(), "authData.profile.locationId");

      const response = await axios({
        url: `${BASE_URL}/secure/catering`,
        method: "put",
        data,
      });
      const _response = _get(response, "data", {});

      return _response;
    } catch (error) {
      return {
        errorMsg: _get(error, "response.data.errors[0].message")
      }
    }
  };
}

export function updateCateringItem(data = {}) {
  return async (dispatch, getState) => {
    try {
      const BASE_URL = _get(getState(), "appData.BASE_URL");
      data.locationId = _get(getState(), "authData.profile.locationId");

      const response = await axios({
        url: `${BASE_URL}/secure/catering/item`,
        method: "put",
        data,
      });
      const _response = _get(response, "data", {});

      return _response;
    } catch (error) {
      return {
        errorMsg: _get(error, "response.data.errors[0].message")
      }
    }
  };
}
