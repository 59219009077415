import React, { useEffect, useState } from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import { getTimeZone } from "../../actions/momentActions";
import Grid from "@material-ui/core/Grid";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import Error from "../Error";
import Success from "../Success";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createOrder } from "../../actions/orderActions";
import { getUsers } from "../../actions/userActions";
import { getMenusItems } from "../../actions/menu/menuActions";
import validate from "./validate";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

function CreateInquiry(props) {
  const [errorMessage, setError] = useState("");
  const [successMessage, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const isSmallScreen = window.innerWidth < 500;
  const [users, setUsers] = useState([]);
  const [items, setItems] = useState([{ id: "", quantity: "", notes: "" }]);
  const [allItems, setAllItems] = useState([]);

  useEffect(() => {
    getAllUsers({ skip: 0, limit: 250 });
    getAllItems();
    // eslint-disable-next-line
  }, []);

  async function getAllUsers() {
    let response = await props.getUsers();
    setUsers(response);
  }

  async function getAllItems() {
    let response = await props.getMenusItems();
    setAllItems(response);
  }

  const save = async (params) => {
    setLoading(true);
    let response;
    if (!params._id) {
      response = await props.createOrder(params);
    } else {
      params = { payload: params, id: params._id };
      response = await props.createOrder(params);
    }
    if (response._id) {
      setLoading(false);
      setSuccess("Order created successfully");
      setTimeout(() => props.onClose(), 2000);
    } else {
      setLoading(false);
      setError("Please try after sometime.");
    }
  };

  const { classes, constants } = props;

  const initialValues = {
    status: "ACTIVE",
    customerId: "",
    customerMsg: "",
    notes: "",
    kitchenMsg: "",
    paymentStatus: "",
    rate: {},
    items: [],
    notif: {},
  };

  const addItemRow = () => {
    const item = {
      rate: "",
      name: "",
    };
    setItems([...items, item]);
  };

  return (
    <Dialog
      fullScreen={isSmallScreen}
      fullWidth={true}
      maxWidth={"md"}
      className={classes.wrapper}
      open={!!props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" onClose={props.handleClose}>
        <Typography>{"Catering"}</Typography>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={props.handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={save}
          enableReinitialize={true}
        >
          {({ values, handleSubmit, setFieldValue }) => {
            return (
              <Form style={{ padding: "20px 10px" }}>
                <Grid container spacing={3}>
                  {/* <SelectBox
                                    md={2}
                                    name="status"
                                    label="Status"
                                    disabled={true}
                                    items={constants.ALL_STATUS_OPTIONS}
                                /> */}

                  <SelectBox
                    md={3}
                    name="customerId"
                    label="Customer"
                    selLabel={"firstName"}
                    selValue={"_id"}
                    items={users}
                  />

                  <InputBox md={3} name="kitchenMsg" label="Kitchen Msg" />
                  <SelectBox
                    md={3}
                    name="paymentStatus"
                    label="Payment Status"
                    selLabel={"id"}
                    selLabelThree={"name"}
                    items={constants.PAYMENT_STATUS}
                  />

                  <SelectBox
                    md={3}
                    name="type"
                    label="Type"
                    selLabel={"id"}
                    selLabelThree={"name"}
                    items={constants.TYPE}
                  />

                  <InputBox name="notes" label="Note" />
                </Grid>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  style={{ marginTop: 20, padding: 8 }}
                >
                  <Typography>Items</Typography>
                </Grid>
                {items.map((e, i) => {
                  return (
                    <Grid container spacing={2} alignItems="center" key={i}>
                      <SelectBox
                        md={3}
                        name={"items_" + i}
                        label="Items"
                        selLabel={"name"}
                        selValue={"_id"}
                        value={e.id}
                        items={allItems}
                        onChange={(el) => {
                          e.id = el.target.value;
                          const item = allItems.find((it) => it._id === e.id);
                          e.name = item.name;
                          e.price = item.price.rate;
                          items[i] = e;
                          setItems((arr) => [...items]);
                        }}
                      />

                      <InputBox
                        md={2}
                        value={e.quantity}
                        name={"quantity_" + i}
                        label="Quantity"
                        type="number"
                        onChange={(el) => {
                          e.quantity = el.target.value;
                          items[i] = e;
                          setItems((arr) => [...items]);
                        }}
                      />
                      <InputBox
                        md={4}
                        value={e.notes}
                        name={"note_" + i}
                        label="Notes"
                        onChange={(el) => {
                          e.notes = el.target.value;
                          items[i] = e;
                          setItems((arr) => [...items]);
                        }}
                      />
                    </Grid>
                  );
                })}
                <Grid item md={2} style={{ marginTop: 12 }}>
                  <Button
                    onClick={addItemRow}
                    type="button"
                    variant="outlined"
                    color="primary"
                    style={{}}
                  >
                    Add
                  </Button>
                </Grid>

                {successMessage && <Success message={successMessage} />}
                {errorMessage && <Error message={errorMessage} />}
                <div>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    style={{ marginTop: 12 }}
                  >
                    {loading ? "Saving..." : "SAVE"}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "equipmentData.error", ""),
    loading: _get(state, "accountData.accountCreateBusy", false),
    accountValues: _get(state, "form.accountForm.values", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMenusItems,
      getUsers,
      createOrder,
      getTimeZone,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(CreateInquiry)
);
