import _get from "lodash/get";
import * as TYPES from "../auth/authActionTypes";
const defaultState = {
    appBusy: false,
    preCheck: true,
    isAdmin: false,
    isDriver: false,
    isManager: false,
    termsBusy: false,
    needAutorization: false,
    accountBaseUrl: ""
};

export default function auth(state = defaultState, action) {
    switch (action.type) {
        case "PROFILE":
        const roles =  _get(action, 'value.roles', []);
        const isAdmin = roles.includes('ADMIN')
        const isManager = roles.includes('MANAGER')
            return {
                ...state,
                isAdmin,
                isManager,
                profile: action.value
            };
        case "SIGNIN_ERROR":
            return {
                ...state,
                signInError: action.value
            };
        case "NEED_AUTORIZATION":
            return {
                ...state,
                needAutorization: action.value
            };
        case TYPES.TERMS_BUSY:
            return {
                ...state,
                termsBusy: action.value
            };
        default:
            return state;
    }
}
