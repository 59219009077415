import _get from "lodash/get";
import moment from "moment";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FilterListIcon from "@material-ui/icons/FilterList";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { DATES_SEARCH } from "../../utils/constants";
import { formatCurrency } from "../../utils/numberHelpers";
import {
  formatUtcDateTime,
  getDay,
  formatDateOnly,
} from "../../utils/momentHelpers";
import TbBillsModal from "./Tb/TbBillsModal";
import { Formik, Form } from "formik";
import InputDateBox from "../../components/InputDateBox";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import Error from "../Error";
import Success from "../Success";

function catculateTotal(items) {
  let food = 0;
  let drinks = 0;
  let alcohol = 0;
  let tips = 0;
  let gratuity = 0;
  let discount = 0;
  let voidAmt = 0;
  let salesTax = 0;
  let grossSales = 0;
  let parties = 0;
  let bills = 0;
  let seats = 0;
  for (let i = 0; i < items.length; i++) {
    const sel = items[i];
    const totals = sel.totals || {};
    const detail = sel.detail || {};

    food = food + parseFloat(totals.food);
    drinks = drinks + parseFloat(totals.drinks);
    alcohol = alcohol + parseFloat(totals.alcohol);
    tips = tips + parseFloat(totals.tip);
    gratuity = gratuity + parseFloat(totals.gratuity);
    discount = discount + parseFloat(totals.discount);
    voidAmt = voidAmt + parseFloat(totals.void);
    salesTax = salesTax + parseFloat(totals.tax);
    grossSales = grossSales + parseFloat(totals.total);
    seats = seats + parseInt(detail.customer_count);
  }

  const total = food + drinks + alcohol;
  const gross = total + tips + gratuity + salesTax;
  let spendPerSeat = gross / seats;
  let spendPerParty = gross / items.length;

  // console.log(`${food} + ${drinks} + ${alcohol} + ${salesTax} + ${gratuity}`);
  // console.log("T = ", food + drinks + alcohol + salesTax  + gratuity);

  return {
    food: formatCurrency(food),
    drinks: formatCurrency(drinks),
    alcohol: formatCurrency(alcohol),
    total: formatCurrency(total),

    tips: formatCurrency(tips),
    gratuity: formatCurrency(gratuity),
    salesTax: formatCurrency(salesTax),
    gross: formatCurrency(gross),

    discount: formatCurrency(discount),
    voidAmt: formatCurrency(voidAmt),

    grossSales: formatCurrency(grossSales),
    parties: items.length,
    bills: items.length,
    seats,
    spendPerSeat: formatCurrency(spendPerSeat),
    spendPerParty: formatCurrency(spendPerParty),
  };
}

function catculateByDay(items) {
  let days = {};

  for (let i = 0; i < items.length; i++) {
    const sel = items[i] || {};
    const totals = sel.totals || {};
    const food = parseFloat(totals.food);
    const drinks = parseFloat(totals.drinks);
    const alcohol = parseFloat(totals.alcohol);

    const date = formatDateOnly(sel.bill_date);

    days[date] = days[date] || {};
    days[date].day = getDay(sel.bill_date);

    if (!days[date].food) {
      days[date].food = food;
    } else {
      days[date].food = days[date].food + food;
    }

    if (!days[date].drinks) {
      days[date].drinks = drinks;
    } else {
      days[date].drinks = days[date].drinks + drinks;
    }

    if (!days[date].alcohol) {
      days[date].alcohol = alcohol;
    } else {
      days[date].alcohol = days[date].alcohol + alcohol;
    }

    days[date].data = days[date].data || [];
    days[date].data.push(sel);
  }

  return days;
}

function RenderItem(props) {
  return (
    <div style={{ fontWeight: props.bold ? 800 : "300" }}>
      <span style={{ display: "inline-block", minWidth: 200 }}>
        {props.label}
      </span>
      {`${props.value}`}
    </div>
  );
}
export default function RenderTotal(props) {
  const items = props.items || [];
  const isCategory = props.isCategory || false;
  const isDaily = props.isDaily || false;

  const average = catculateTotal(items);
  const days = catculateByDay(items);

  const daysArray = Object.keys(days).map((item) => {
    let data = days[item] || {};
    data.date = item;
    return data;
  });

  const foodTotal = daysArray.reduce((a, b) => a + parseFloat(b.food), 0);
  const drinksTotal = daysArray.reduce((a, b) => a + parseFloat(b.drinks), 0);
  const alcoholTotal = daysArray.reduce((a, b) => a + parseFloat(b.alcohol), 0);
  const allTotal = foodTotal + drinksTotal + alcoholTotal;

  return (
    <div style={{ padding: 20 }}>
      <Grid container spacing={2}>
        {isCategory && (
          <Grid item md={isDaily ? 5 : 12}>
            <Card raised={true}>
              <CardContent>
                <h4>Sales by Sales Category</h4>
                <h4>For month of {props.month}</h4>
                <RenderItem label="Food" value={average.food} />
                <RenderItem
                  label="Non Alcoholic Beverages"
                  value={average.drinks}
                />
                <RenderItem
                  label="Alcoholic Beverages"
                  value={average.alcohol}
                />
                <RenderItem bold={true} label="Total" value={average.total} />
                <br />
                <RenderItem label="Tips" value={average.tips} />
                <RenderItem label="Gratuity" value={average.gratuity} />
                <RenderItem label="Sales Tax" value={average.salesTax} />
                <RenderItem
                  bold={true}
                  label="Gross Sales"
                  value={average.gross}
                />
                <br />

                <RenderItem label="Parties" value={average.parties} />
                <RenderItem label="Bills" value={average.bills} />
                <RenderItem label="Seats" value={average.seats} />
                <br />

                <RenderItem
                  label="Average spend per seat"
                  value={average.spendPerSeat}
                />
                <RenderItem
                  label="Average spend per party"
                  value={average.spendPerParty}
                />
              </CardContent>
            </Card>
          </Grid>
        )}
        {isDaily && (
          <Grid item md={isCategory ? 7 : 12}>
            <Card raised={true}>
              <CardContent>
                <h4>Sales by Day</h4>
                <TableContainer
                  component={Paper}
                  style={{ maxHeight: isCategory ? 355 : 200 }}
                >
                  <Table className={{}} aria-label="Date Table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell>Food</TableCell>
                        <TableCell>Drinks</TableCell>
                        <TableCell>Alcohol</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {daysArray.map((data) => {
                        const _food = data.food;
                        const _drinks = data.drinks;
                        const _alcohol = data.alcohol;
                        const _total = _food + _drinks + _alcohol;
                        return (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {`${data.date} ${data.day}`}
                            </TableCell>
                            <TableCell style={{ fontWeight: 500 }}>
                              {formatCurrency(_total)}
                            </TableCell>
                            <TableCell>{formatCurrency(_food)}</TableCell>
                            <TableCell>{formatCurrency(_drinks)}</TableCell>
                            <TableCell>{formatCurrency(_alcohol)}</TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell style={{ fontWeight: 600 }}>
                          {formatCurrency(allTotal)}
                        </TableCell>
                        <TableCell style={{ fontWeight: 500 }}>
                          {formatCurrency(foodTotal)}
                        </TableCell>
                        <TableCell style={{ fontWeight: 500 }}>
                          {formatCurrency(drinksTotal)}
                        </TableCell>
                        <TableCell style={{ fontWeight: 500 }}>
                          {formatCurrency(alcoholTotal)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
