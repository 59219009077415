import axios from "../axios";
import _get from "lodash/get";
import * as TYPES from "./menuActionTypes";

export const setMenu = (value) => ({
  type: TYPES.MENU,
  value,
});

export const setMenuCount = (value) => ({
  type: TYPES.MENU_COUNT,
  value,
});

export const fmcsaBusy = (value) => ({
  type: TYPES.MENU_BUSY,
  value,
});

export const setMenuHistory = (value) => ({
  type: TYPES.MENUHISTORY,
  value,
});

export const setMenuHistoryCount = (value) => ({
  type: TYPES.MENUHISTORY_COUNT,
  value,
});

export function getMenusItems(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");

    params.locationId = _get(getState(), "authData.profile.locationId");
    return axios({
      // url: `${BASE_URL}/secure/menu/item?locationId=${locationId}&menuId=${menuId}&groupId=${groupId}`,
      url: `${BASE_URL}/secure/menu/item`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", []);

        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(fmcsaBusy(false));
        return response;
      });
  };
}

export function getMenusGroups(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");

    params.locationId = _get(getState(), "authData.profile.locationId");
    return axios({
      // url: `${BASE_URL}/secure/menu/group?locationId=${locationId}&menuId=${menu}`,
      url: `${BASE_URL}/secure/menu/group`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", []);
        dispatch(setMenu(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(fmcsaBusy(false));
        return response;
      });
  };
}

export function getMenus(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");

    params.locationId = _get(getState(), "authData.profile.locationId");
    return axios({
      url: `${BASE_URL}/secure/menu`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", []);
        dispatch(setMenu(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(fmcsaBusy(false));
        return response;
      });
  };
}

export function addMenu(data) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const locationId = _get(getState(), "authData.profile.locationId");
    const userId = _get(getState(), "authData.profile.userId");

    data.userId = userId;
    data.locationId = locationId;

    return axios({
      url: `${BASE_URL}/secure/menu`,
      method: "post",
      data,
    })
      .then((response) => {
        const _response = _get(response, "data");
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(fmcsaBusy(false));
        return response;
      });
  };
}

export function updateMenu(data) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    data.locationId = _get(getState(), "authData.profile.locationId");

    return axios({
      url: `${BASE_URL}/secure/menu`,
      method: "put",
      data,
    })
      .then((response) => {
        const _response = _get(response, "data");
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(fmcsaBusy(false));
        return response;
      });
  };
}

export function addMenuGroup(data) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const locationId = _get(getState(), "authData.profile.locationId");
    data.locationId = locationId;

    const userId = _get(getState(), "authData.profile.userId");
    data.userId = userId;

    return axios({
      url: `${BASE_URL}/secure/menu/group`,
      method: "post",
      data,
    })
      .then((response) => {
        const _response = _get(response, "data");
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(fmcsaBusy(false));
        return response;
      });
  };
}

export function updateMenuGroup(data) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const locationId = _get(getState(), "authData.profile.locationId");
    data.locationId = locationId;

    return axios({
      url: `${BASE_URL}/secure/menu/group`,
      method: "put",
      data,
    })
      .then((response) => {
        const _response = _get(response, "data");
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(fmcsaBusy(false));
        return response;
      });
  };
}

export function addMenuItem(data) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const locationId = _get(getState(), "authData.profile.locationId");
    data.locationId = locationId;

    const userId = _get(getState(), "authData.profile.userId");
    data.userId = userId;

    return axios({
      url: `${BASE_URL}/secure/menu/item`,
      method: "post",
      data,
    })
      .then((response) => {
        const _response = _get(response, "data");
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(fmcsaBusy(false));
        return response;
      });
  };
}

export function updateMenuItem(data) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const locationId = _get(getState(), "authData.profile.locationId");

    return axios({
      url: `${BASE_URL}/secure/menu/item?locationId=${locationId}`,
      method: "put",
      data,
    })
      .then((response) => {
        const _response = _get(response, "data");
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(fmcsaBusy(false));
        return response;
      });
  };
}

export function addMenuGroupMapping(data) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const locationId = _get(getState(), "authData.profile.locationId");

    data.locationId = locationId;

    return axios({
      url: `${BASE_URL}/secure/menu/menu-group`,
      method: "post",
      data,
    })
      .then((response) => {
        const _response = _get(response, "data");
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(fmcsaBusy(false));
        return response;
      });
  };
}

export function deleteMenuGroupMapping(params) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const locationId = _get(getState(), "authData.profile.locationId");

    params.locationId = locationId;

    return axios({
      url: `${BASE_URL}/secure/menu/menu-group`,
      method: "delete",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data");
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(fmcsaBusy(false));
        return response;
      });
  };
}

export function addGroupItem(data) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const locationId = _get(getState(), "authData.profile.locationId");

    data.locationId = locationId;

    return axios({
      url: `${BASE_URL}/secure/menu/group-item`,
      method: "post",
      data,
    })
      .then((response) => {
        const _response = _get(response, "data");
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(fmcsaBusy(false));
        return response;
      });
  };
}

export function deleteGroupItem(params) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const locationId = _get(getState(), "authData.profile.locationId");

    params.locationId = locationId;

    return axios({
      url: `${BASE_URL}/secure/menu/group-item`,
      method: "delete",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data");
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(fmcsaBusy(false));
        return response;
      });
  };
}
