import _get from "lodash/get";
import React, { useState } from "react";
import { Field } from "formik";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const InputField = ({
  value,
  field,
  type,
  disabled,
  form: { touched, errors, values, handleChange, handleBlur },
  onChange,
  onBlur,
  autoComplete = "on",
  helpText,
  ...props
}) => {
  const [inputType, setInputType] = useState(type);
  let error = _get(errors, `${field.name}`);
  let touch = _get(touched, `${field.name}`);
  let value_ = _get(values, `${field.name}`) || value;

  const showPwd = type === "password";

  const handleClickShowPassword = () => {
    if (inputType === "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      type={inputType}
      name={field.name}
      label={props.label}
      placeholder={props.placeholder}
      value={value_}
      autoComplete={autoComplete}
      onChange={(e) => {
        onChange(e);
        handleChange(e);
      }}
      onBlur={(e) => {
        onBlur(e);
        handleBlur(e);
      }}
      helperText={error ? error : helpText}
      error={touch && Boolean(error)}
      fullWidth
      InputProps={{
        readOnly: disabled,
        endAdornment: showPwd ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    />
  );
};

const InputBox = ({
  name,
  label,
  type = "text",
  placeholder,
  disabled = false,
  onBlur = () => {},
  onChange = () => {},
  md = 4,
  xs = 12,
  value,
  helpText = "",
  style = {},
}) => {
  return (
    <Grid item md={md} xs={xs} style={style}>
      <Field
        type={type}
        name={name}
        // autoComplete={autoComplete}
        label={label || name}
        disabled={disabled}
        component={InputField}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        helpText={helpText}
      />
    </Grid>
  );
};

export default InputBox;
