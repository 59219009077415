import _get from "lodash/get";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import GroupIcon from "@material-ui/icons/Group";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import SignoutIcon from "@material-ui/icons/ExitToAppOutlined";
import { withStyles } from "@material-ui/core/styles";
import { LOGO_LINE } from "../../utils/logos";

const lightColor = "rgba(255, 255, 255, 0.7)";

const styles = theme => ({
    secondaryBar: {
        zIndex: 0
    },
    menuButton: {
        marginLeft: -theme.spacing(1)
    },
    iconButtonAvatar: {
        padding: 4
    },
    link: {
        textDecoration: "none",
        color: lightColor,
        "&:hover": {
            color: theme.palette.common.white
        }
    },
    button: {
        borderColor: lightColor
    },
    account: {
        textTransform: "uppercase",
        fontWeight: 400
    }
});

export function Header(props) {
    const logOut = () => {
        props.handleUserIconClick();
    };

    const { classes, onDrawerToggle, adminKey, account } = props;

    return (
        <React.Fragment>
            <AppBar color="primary" position="sticky" elevation={0} >
                <Toolbar>
                    <Grid container spacing={0} alignItems="center">
                        <Hidden mdDown>
                            <Grid item className="d-flex align-items-center">
                                <img
                                    src={LOGO_LINE}
                                    alt={"The Yellow Chilli"}
                                    style={{ maxWidth: 150, maxHeight: 55, margin: "auto" }}
                                />
                            </Grid>
                        </Hidden>
                        <Hidden lgUp>
                            <Grid item>
                                <IconButton
                                    color="inherit"
                                    aria-label="Open drawer"
                                    onClick={onDrawerToggle}
                                    className={classes.menuButton}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Grid>
                        </Hidden>
                        <Grid item xs />
                        {adminKey && (
                            <Grid item className={classes.account}>
                                {account}
                            </Grid>
                        )}
                        {adminKey && (
                            <Grid item>
                                <Tooltip title="All Accounts">
                                    <IconButton
                                        color="inherit"
                                        onClick={
                                            props.handleAppAccount
                                        }
                                    >
                                        <GroupIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )}
                        {/*<Grid item>
                                <Tooltip title="Alerts • No alters">
                                    <IconButton color="inherit">
                                        <NotificationsIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>*/}

                        <Grid item>
                            <Tooltip title="Sign Out">
                                <IconButton
                                    onClick={logOut}
                                    color="inherit"
                                    className={classes.iconButtonAvatar}
                                >
                                    <SignoutIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}
Header.propTypes = {
    classes: PropTypes.object.isRequired,
    onDrawerToggle: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        account: _get(state, "authData.profile.account.name", "")
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

// export default withStyles(styles)(Header);
export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Header)
);
