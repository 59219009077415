import axios from "../axios";
import _get from "lodash/get";
import * as cookie from "react-cookie";
import * as TYPES from "./authActionTypes";
import { setSelectedAccountId, setTimeZone } from "../app/appActions";

const cookies = new cookie.Cookies();

export const setProfile = value => ({
    type: TYPES.PROFILE,
    value
});

export const isManager = value => ({
    type: TYPES.IS_MANANGER,
    value
});

export const isDriver = value => ({
    type: TYPES.IS_DRIVER,
    value
});

export const isAdmin = value => ({
    type: TYPES.IS_ADMIN,
    value
});

export const setError = value => ({
    type: TYPES.SIGNIN_ERROR,
    value
});

export const appBusy = value => ({
    type: TYPES.APP_BUSY,
    value
});

export const termsBusy = value => ({
    type: TYPES.TERMS_BUSY,
    value
});

export const preCheck = value => ({
    type: TYPES.PRE_CHECK,
    value
});

export const setLogOut = value => ({
    type: TYPES.NEED_AUTORIZATION,
    value
});

export function signIn({ username, password }) {
    return (dispatch, getState) => {
        dispatch(appBusy(true));
        dispatch(setLogOut(false));
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        return axios({
            url: `${BASE_URL}/auth/signin`,
            timeout: 20000,
            method: "post",
            data: { username, password }
        })
            .then(response => {
                const _response = response.data;
                // console.log(_response)
                const token = _response.access_token;
                cookies.set("token", token, {
                    path: "/",
                    maxAge: 43200
                });

                dispatch(setProfile(_response.user));
                return _response;
            })
            .catch(error => {
                const statusCode = _get(error, "response.status");
                const statusText = _get(error, "response.statusText");
                const message =
                    _get(error, "response.data.errors[0].message") ||
                    `${statusCode} ${statusText}`;
                dispatch(setError(message));
                return message;
            })
            .then(response => {
                dispatch(appBusy(false));
                dispatch(getProfile());
                return response;
            });
    };
}

export function signOut() {
    return (dispatch, getState) => {
        cookies.remove("token", {
            path: "/",
            maxAge: 43200
        });
        dispatch(setLogOut(true));
        return true;
    };
}

export function getProfile(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        dispatch(appBusy(true));

        return axios({
            url: `${BASE_URL}/secure/profile`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                const accountId = _get(response, "data.user.accountId");
                const timezone = _get(response, "data.terminal.timezone");

                dispatch(setSelectedAccountId(accountId));
                dispatch(setProfile(_response));
                dispatch(setTimeZone(timezone));
                dispatch(appBusy(false));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                dispatch(appBusy(false));
                dispatch(setError(message));
            })
            .then(response => {
                dispatch(preCheck(true));
                dispatch(appBusy(false));
                return response;
            });
    };
}

export function acceptTerms(terms) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        dispatch(termsBusy(true));
        return axios({
            url: `${BASE_URL}/terms`,
            timeout: 20000,
            method: "put",
            data: {
                terms
            }
        })
            .then(response => {
                const _response = _get(response, "data", {});
                const accountId = _get(response, "data.user.accountId");

                dispatch(setSelectedAccountId(accountId));
                dispatch(setProfile(_response));

                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return { message };
            })
            .then(response => {
                dispatch(preCheck(true));
                dispatch(termsBusy(false));
                return response;
            });
    };
}

export function passwordResetRequest({ email }) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        return axios({
            url: `${BASE_URL}/passwordReset`,
            timeout: 20000,
            method: "post",
            data: {
                email
            }
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return { message };
            })
            .then(response => {
                return response;
            });
    };
}

export function passwordResetUpdate({ token, userId, password }) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        return axios({
            url: `${BASE_URL}/passwordReset`,
            timeout: 20000,
            method: "put",
            data: {
                token,
                userId,
                password
            }
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return { message };
            })
            .then(response => {
                return response;
            });
    };
}

export function pushTokenUpdate({ fcmWebToken, platform }) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");
        const userId = _get(getState(), "authData.profile.user.userId");
        return axios({
            url: `${BASE_URL}/${accountId}/user/${userId}/fcm`,
            timeout: 20000,
            method: "put",
            data: { fcmWebToken, platform }
        })
            .then(response => {
                const _response = _get(response, "data", {});
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return { message };
            })
            .then(response => {
                return response;
            });
    };
}
