import { React, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { getCaterings } from "../../../actions/catering/cateringActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DetailPage from "./DetailPage";
import EditPage from "./EditPage";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  grid: {},
  card: {},
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
});

function CateringDetail(props) {
  const classes = { props };
  const [catering, setCatering] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  let params = useParams();

  const getCateringById = async () => {
    setLoading(true);
    try {
      const response = await props.getCaterings({ id: params.cateringId });
      if (response && response.id) {
        setCatering(response);
      }
    } catch (e) {
      console.log("e :::: ", e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCateringById();
    // eslint-disable-next-line
  }, []);

  const onEditClick = async () => {
    setIsEdit(true);
  };

  const onEditClose = async () => {
    setIsEdit(false);
  };

  return (
    <div style={{ padding: 10 }}>
      <EditPage open={isEdit} data={catering} handleClose={onEditClose} />
      <Grid container spacing={1}>
        <Grid item xs={12} md={8} className={classes.root}>
          <Card raised className={classes.card}>
            <CardContent>
              {catering && <DetailPage catering={catering} />}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4} className={classes.grid}>
          <Card raised className={classes.card}>
            <CardContent>
              <Grid container>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <ButtonGroup
                    color="primary"
                    aria-label="outlined primary button group"
                  >
                    {/*<Button>PRINT</Button>*/}
                    <Button onClick={onEditClick}>EDIT</Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCaterings,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(CateringDetail)
);
