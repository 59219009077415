import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function CheckboxListSecondary(props) {
  const classes = useStyles();
  const isCatering = props.data.isCatering || false;
  const isDineIn = props.data.isDineIn || false;
  const isPickup = props.data.isPickup || false;

  return (
    <List dense className={classes.root}>
      <ListItem key={"is-catering"} button>
        <ListItemText id={`is-catering`} primary={`Catering`} />
        <ListItemSecondaryAction>
          {isCatering ? "ON" : "OFF"}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem key={"is-dine-in"} button>
        <ListItemText id={`is-dine-in`} primary={`Dine In`} />
        <ListItemSecondaryAction>
          {isDineIn ? "ON" : "OFF"}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem key={"is-pick-up"} button>
        <ListItemText id={`id-is-pick-up`} primary={`Pick Up`} />
        <ListItemSecondaryAction>
          {isPickup ? "ON" : "OFF"}
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}
