import * as TYPES from "./menuActionTypes";
const defaultState = {
	menus: [],
	count: 0,
	menu: {}
};

export default function menu(state = defaultState, action) {
	switch (action.type) {
		case TYPES.MENUS:
			return {
				...state,
				menus: action.value
			};
		case TYPES.MENU_COUNT:
			return {
				...state,
				count: action.value
			};
		case TYPES.MENU:
			return {
				...state,
				menu: action.value
			};
		default:
			return state;
	}
}
