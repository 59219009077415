export const initialValues = {
  name: "",
  fm: "",
  qty: "",
  rate: "",
  total: "",
};

export const validate = (values) => {
  const errors = {};
  const requiredFields = ["name", "rate"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = `Required`;
    }
  });

  return errors;
};
