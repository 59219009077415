import React, { useState } from "react";
import _get from "lodash/get";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import { passwordResetRequest } from "../../actions/auth/authActions";
import Grid from "@material-ui/core/Grid";
import Error from "../Error";
import Success from "../Success";
import { validateForgetPwd } from "./validate";
import { Formik, Form } from "formik";
import InputBox from "../../components/InputBox";
import { connect } from "react-redux";

const styles = theme => ({
    wrapper: {},
    DialogActions: {
        marginTop: 40,
        justifyContent: "center"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

function TermsDialog(props) {
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")

    const onResetRequest = async params => {
        setLoading(true)
        const response = await props.passwordResetRequest(params);

        if (response.accepted) {

            setLoading(false)
            setSuccessMessage("Please check your email for password reset instructions.")
            setTimeout(() => props.onClose(), 2000);
        } else {
            setLoading(false)
            setErrorMessage("Please try after sometime.")
        }
    };

    const { classes } = props;

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            className={classes.wrapper}
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <h2 style={{ textAlign: "center", padding: 0 }}>
                {"Forget Password"}
            </h2>
            <DialogTitle
                id="alert-dialog-title"
                onClose={props.onClose}
            >
                <IconButton
                    aria-label="Close"
                    className={classes.closeButton}
                    onClick={props.onClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <Formik
                    initialValues={{ email: "" }}
                    validate={validateForgetPwd}
                    onSubmit={onResetRequest}
                >
                    {({ values, handleSubmit, setFieldValue }) => {
                        return (
                            <Form style={{ padding: "20px 10px" }}>
                                <Grid container spacing={3}>
                                    <InputBox
                                        md={12}
                                        name="email"
                                        label="Email"
                                    />
                                </Grid>
                                <Grid container spacing={2}>
                                    <Error
                                        top={20}
                                        message={errorMessage}
                                    />
                                    <Success
                                        top={20}
                                        message={successMessage}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    style={{
                                        marginTop: 45,
                                        textAlign: "center"
                                    }}
                                >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={loading}
                                        style={{
                                            minWidth: 250
                                        }}
                                    >
                                        {loading
                                            ? "Loading..."
                                            : "Send Email"}
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    style={{
                                        marginTop: 15,
                                        textAlign: "center"
                                    }}
                                >
                                    <Button onClick={props.onClose}>
                                        {"Close"}
                                    </Button>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

const mapStateToProps = state => {
    return {
        loading: _get(state, "authData.termsBusy", false),
        constants: _get(state, "appData.constants", {}),
        errorMessage: _get(state, "authData.errorMessage", "")
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            passwordResetRequest
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TermsDialog))
