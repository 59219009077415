import { React, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import Divider from '@material-ui/core/Divider';
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { getMenus } from "../../actions/menu/menuActions";
import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import AddMenu from "./Menu/AddMenu";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";

const styles = (theme) => ({
  paper: {
    margin: 15,
    overflow: "hidden",
  },
  title: {
    flexGrow: 1,
  },
});

function MenuDetail(props) {
  const isDineIn = props.data.isDineIn;
  const isPickup = props.data.isPickup;
  const isCatering = props.data.isCatering;

  return (
    <>
      <Chip
        size="small"
        icon={isDineIn ? <DoneIcon /> : <ClearIcon />}
        label="Dine In"
        color="primary"
        variant="outlined"
      />{" "}
      <Chip
        size="small"
        icon={isPickup ? <DoneIcon /> : <ClearIcon />}
        label="Pick Up"
        color="primary"
        variant="outlined"
      />{" "}
      <Chip
        size="small"
        icon={isCatering ? <DoneIcon /> : <ClearIcon />}
        label="Catering"
        color="primary"
        variant="outlined"
      />
    </>
  );
}

function Menu(props) {
  const { classes } = props;
  const [menus, setMenus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editMenu, setEditMenu] = useState(false);
  const [addMenu, setAddMenu] = useState(false);

  useEffect(() => {
    _setMenus();
    // eslint-disable-next-line
  }, []);

  async function _setMenus() {
    try {
      setLoading(true);
      let response = await props.getMenus();
      if (response.length > 0) {
        setMenus(response);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <>
      {addMenu ? (
        <AddMenu
          open={addMenu}
          handleClose={() => {
            setAddMenu(false);
          }}
          onClose={() => {
            setAddMenu(false);
            _setMenus();
          }}
        />
      ) : (
        ""
      )}

      {editMenu && (
        <AddMenu
          editMenu={editMenu}
          open={!!editMenu}
          handleClose={() => {
            setEditMenu(null);
          }}
          onClose={() => {
            setEditMenu(null);
            _setMenus();
          }}
        />
      )}

      <Card className={classes.root}>
        <CardContent>
          <Grid container className={classes.root} spacing={2}>
            <Grid item md={12} xs={12}>
              <AppBar position="static">
                <Toolbar>
                  <Typography
                    variant="h6"
                    className={classes.title}
                  >{`Total ${menus.length}`}</Typography>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setAddMenu(true);
                    }}
                  >
                    ADD
                  </Button>
                </Toolbar>
              </AppBar>
            </Grid>
            <Grid item md={12} xs={12}>
              {loading ? (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress />
                </div>
              ) : (
                <List className={classes.root}>
                  {menus.map((menu) => (
                    <div key={menu.id}>
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={menu.name}
                          secondary={`${menu.status}, ${menu.type}`}
                        />
                        <ListItemSecondaryAction>
                          <ButtonGroup
                            color="primary"
                            aria-label="outlined primary button group"
                          >
                            <Button
                              size="small"
                              type="button"
                              onClick={() => {
                                setEditMenu(menu);
                              }}
                            >
                              EDIT
                            </Button>

                            <Button size="small" type="button">
                              <Link
                                to={`/menu/${menu.id}`}
                                style={{
                                  textDecoration: "none",
                                  color: "#009BE5",
                                }}
                              >
                                DETAIL
                              </Link>
                            </Button>
                            <Button size="small" type="button">
                              <Link
                                to={`/menu-group?menuId=${menu.id}`}
                                style={{
                                  textDecoration: "none",
                                  color: "#009BE5",
                                }}
                              >
                                GROUPS
                              </Link>
                            </Button>
                          </ButtonGroup>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </div>
                  ))}
                </List>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMenus,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Menu)
);
