export const initialValues = {
  name: "",
  type: "FOOD",
  description: "",
  status: "ACTIVE",
  price: {
    amount: "",
    by: "UNIT",
  },
  modifiers: [
    { name: "Veg", rate: "0" },
    { name: "Chicken", rate: "1.00" },
  ],
  thumbnailUrl: "",
};

export const validate = (values) => {
  const errors = {};
  const requiredFields = ["name", "type", "description"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = `Required`;
    }
  });
  return errors;
};
