import _get from "lodash/get";
import moment from "moment";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FilterListIcon from "@material-ui/icons/FilterList";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { DATES_SEARCH } from "../../utils/constants";
import { formatCurrency } from "../../utils/numberHelpers";
import {
  formatUtcDateTime,
  getDay,
  getMonthYear,
} from "../../utils/momentHelpers";
import TbBillsModal from "./Tb/TbBillsModal";
import { Formik, Form } from "formik";
import InputDateBox from "../../components/InputDateBox";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import Error from "../Error";
import Success from "../Success";
import RenderTotal from "./RenderTotal";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function RenderRowItems(props) {
  const label = props.label;
  let value = props.value;
  let value2 = props.value2;
  let size = props.size || "65";

  if (props.type === "cur") {
    if (parseFloat(value) === 0) {
      return null;
    } else {
      value = formatCurrency(value);
    }
  }

  if (value2 === "American_Express") {
    value2 = "Amex";
  }

  return (
    <div>
      <span style={{ display: "inline-block", minWidth: size }}>{label}</span>
      {value} {value2 ? ` (${value2})` : ""}
    </div>
  );
}

export default function BillsTable(props) {
  const classes = useStyles();
  const items = props.items || [];
  const [month, setMonth] = React.useState(getMonthYear());
  const [selected, setSelected] = React.useState([]);
  const [isTb, setIsTb] = useState(false);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [errorMessage, setError] = useState("");
  const [successMessage, setSuccess] = useState("");
  const page = parseInt(props.skip) / parseInt(props.take);

  const handleChangePage = (event, newPage) => {
    const take = props.take;
    const skip = parseInt(newPage) * parseInt(take);

    props.handleChangePage({ take, skip });
  };

  const handleChangeRowsPerPage = (event) => {
    const skip = props.skip;
    const take = parseInt(event.target.value, 10);

    props.handleChangePage({ take, skip });
  };

  const isSelected = (item) => {
    return !!selected.find((_item) => _item.id === item.id);
  };

  function onSearch(values) {
    const _start = values.start
    const _end = values.end;
    setStart(_start);
    setEnd(_end);
    setMonth(getMonthYear(_start.add(2, 'days')));
    props.handleChangePage({
      take: props.take,
      skip: 0,
      start: _start,
      end: _end,
    });
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {isTb && (
          <TbBillsModal open={isTb} handleClose={() => setIsTb(false)} />
        )}

        <Formik
          initialValues={{
            start: moment().startOf("month").format("YYYY-MM-DD hh:mm"),
            end: moment().endOf("month").format("YYYY-MM-DD hh:mm"),
          }}
          enableReinitialize={true}
          onSubmit={onSearch}
        >
          {({ values, handleSubmit, setFieldValue }) => {
            return (
              <Form style={{ padding: "20px 10px" }}>
                <Grid container spacing={3}>
                  <InputDateBox md={4} name="start" label="Start" />
                  <InputDateBox md={4} name="end" label="End" />

                  <Grid item md={4} xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                      size="small"
                      style={{ marginTop: 12, marginRight: 5, minWidth: 100 }}
                    >
                      {loading ? "Searching..." : "Search"}
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      style={{ marginTop: 12, marginRight: 5, minWidth: 100 }}
                      onClick={() => setIsTb(true)}
                    >
                      {"Touch Bistro"}
                    </Button>
                  </Grid>
                </Grid>
                {successMessage && <Success message={successMessage} />}
                {errorMessage && <Error message={errorMessage} />}
              </Form>
            );
          }}
        </Formik>
        <RenderTotal
          month={month}
          items={items}
          isCategory={true}
          isDaily={true}
        />

        <TableContainer style={{ maxHeight: 200 }}>
          <Table
            stickyHeader
            size="small"
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell align={"left"}>Bill#</TableCell>
                <TableCell align={"left"}>Status</TableCell>
                <TableCell align={"left"}>Date</TableCell>
                <TableCell align={"left"}>Amount</TableCell>
                <TableCell align={"left"}>Items</TableCell>
                <TableCell align={"left"}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items &&
                Array.isArray(items) &&
                items.map((row, index) => {
                  const isItemSelected = isSelected(row);
                  const totals = row.totals || {};
                  const detail = row.detail || {};

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`id-${index}-${row.id}`}
                      selected={isItemSelected}
                      color="success"
                    >
                      {/*<TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </TableCell>*/}
                      <TableCell align="left">{`${row.bill_number}`}</TableCell>
                      <TableCell align="left">{row.status}</TableCell>
                      <TableCell align="left">
                        {formatUtcDateTime(row.bill_date)}
                        <RenderRowItems
                          label={"Day: "}
                          value={`${getDay(row.bill_date)}`}
                        />
                        <RenderRowItems
                          label={"Table# "}
                          value={`${detail.table}`}
                        />
                        <RenderRowItems
                          label={"Server: "}
                          value={`${detail.waiter}`}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <RenderRowItems
                          type="cur"
                          label={"Food: "}
                          value={`${totals.food}`}
                        />
                        <RenderRowItems
                          type="cur"
                          label={"Drinks:"}
                          value={`${totals.drinks}`}
                        />
                        <RenderRowItems
                          type="cur"
                          label={"Alcohol:"}
                          value={`${totals.alcohol}`}
                        />
                        <RenderRowItems
                          type="cur"
                          label={"Void: "}
                          value={`${totals.void}`}
                        />
                        <RenderRowItems
                          type="cur"
                          label={"Discount"}
                          value={`${totals.discount}`}
                        />
                        <RenderRowItems
                          type="cur"
                          label={"Gratuity"}
                          value={`${totals.gratuity}`}
                        />
                        <RenderRowItems
                          type="cur"
                          label={"Tax: "}
                          value={`${totals.tax}`}
                        />
                        <RenderRowItems
                          type="cur"
                          label={"Tip: "}
                          value={`${totals.tip}`}
                        />

                        <RenderRowItems
                          type="cur"
                          label={"Paid: "}
                          value={`${totals.paid}`}
                          value2={`${totals.method}`}
                        />
                      </TableCell>
                      <TableCell align="left">
                        {row.items.map((item) => (
                          <div>{`${parseInt(item.quantity)} ${item.name}`}</div>
                        ))}
                      </TableCell>
                      <TableCell align="left">
                        <Button
                          size="small"
                          onClick={() => props.onDelete(row)}
                        >
                          {"DELETE"}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 500, 1000]}
          component="div"
          count={-1}
          rowsPerPage={props.take}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
