import React, { useState } from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { Formik, Form, Field, FieldArray } from "formik";
import InputBox from "../../../../components/InputBox";
import SelectBox from "../../../../components/SelectBox";
import InputDateBox from "../../../../components/InputDateBox";
import AutocompleteInput from "../../../../components/AutocompleteInput";
import {
  CATERING_FORMULA,
  CATERING_RATE_TYPES,
} from "../../../../utils/constants";
import { ACCEPT_OPTIONS, ONSITE_OFFSITE } from "../../../../constants";
import ItemEditPage from "./ItemEditPage";
import EditItemModal from "./EditItemModal";
import RateInfoEdit from "./RateInfoEdit";
import { updateCaterings } from "../../../../actions/catering/cateringActions";

function _formatName(value) {
  let _a = value.split(".");
  return _a[_a.length - 1];
}

const CateringEdit = (props) => {
  const [isRateEdit, setIsRateEdit] = useState(true);
  const [isItemEdit, setIsItemEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setError] = useState("");
  const [successMessage, setSuccess] = useState("");
  const catering = props.data || {};
  const guest = catering.guest || {};
  const rate = catering.rate || [];
  const guestCount = guest.adults;
  const newItem = {
    index: 0,
    name: "",
    rate: 0,
    fm: "M",
    qty: guestCount,
    total: 0,
  };
  // console.log("catering ::: ", catering);

  function onItemsEdit(_item) {
    setSelectedItem(_item);
    setIsItemEdit(true);
  }

  const save = async (values) => {
    console.log("values :::: ", values);
    try {
      setSaving(true);
      const response = await props.updateCaterings(values);
      setSaving(false);
      if (response.id) {
        setSuccess("Updated successfully");
        setTimeout(() => props.handleClose(), 1000);
      } else {
        setError("Please try after sometime.");
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      {isItemEdit && (
        <EditItemModal
          open={isItemEdit}
          catering={catering}
          selectedItem={selectedItem}
          handleClose={() => setIsItemEdit(false)}
        />
      )}
      <Formik initialValues={catering} onSubmit={save}>
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <Grid container spacing={1}>
                <InputBox md={3} name={`contact.firstName`} label="FirstName" />
                <InputBox md={3} name={`contact.lastName`} label="LastName" />
                <InputBox md={3} name={`contact.email`} label="Email" />
                <InputBox md={3} name={`contact.phone`} label="Phone" />
                <InputDateBox md={3} name={`eventDate`} label="EventDate" />
                <SelectBox
                  items={ACCEPT_OPTIONS}
                  md={2}
                  name={`status`}
                  label="Status"
                />
                <SelectBox
                  items={ONSITE_OFFSITE}
                  md={2}
                  name={`type`}
                  label="Type"
                />

                <InputBox
                  md={2}
                  name={`guest.adults`}
                  label="Adults"
                  type="number"
                />
                <InputBox
                  md={2}
                  name={`guest.kids`}
                  label="Kids"
                  type="number"
                />
                <InputBox md={3} name={`address.line1`} label="Street name" />
                <InputBox md={3} name={`address.line2`} label="Apt#" />
                <InputBox md={2} name={`address.city`} label="City" />
                <InputBox md={2} name={`address.state`} label="State" />
                <InputBox md={2} name={`address.zip`} label="Zipcode" />

                <Grid item xs={12} md={12}>
                  <Typography
                    variant="h6"
                    component="h2"
                    style={{ marginTop: 25 }}
                  >
                    {"Menu Items"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <ItemEditPage catering={catering} onItemsEdit={onItemsEdit} />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography
                    variant="h6"
                    component="h2"
                    style={{ marginTop: 25 }}
                  >
                    {"Rate Info"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <RateInfoEdit catering={catering} />
                </Grid>

                <Grid item xs={12} md={12}>
                  <div>Total = </div>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{
                      marginTop: 15,
                      textAlign: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      style={{ minWidth: 250 }}
                      variant="contained"
                      color="primary"
                    >
                      {"SAVE"}
                    </Button>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      style={{
                        marginTop: 15,
                        textAlign: "center",
                      }}
                    >
                      <Button onClick={props.handleClose}>{"Close"}</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCaterings,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CateringEdit);
