import _get from "lodash/get";
import React from "react";
import { Field } from "formik";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

const InputField = ({
    value,
    field,
    disabled,
    form: { touched, errors, values, handleChange, handleBlur },
    onChange,
    onBlur,
    items,
    selValue,
    selLabel,
    selLabelTwo,
    selLabelThree,
    ...props
}) => {
    let error = _get(errors, `${field.name}`);
    let touch = _get(touched, `${field.name}`);
    let value_ = _get(values, `${field.name}`) || value;
    const tName = field.name//.replace(/\./g, "-");

    return (
        <TextField
            name={tName}
            select={true}
            label={props.label}
            placeholder={props.placeholder}
            value={value_ || ""}
            onChange={e => {
                onChange(e);
                handleChange(e);
            }}
            onBlur={e => {
                onBlur(e);
                handleBlur(e);
            }}
            helperText={touch ? error : ""}
            error={touch && Boolean(error)}
            fullWidth={true}
            InputProps={{
                readOnly: disabled
            }}
        >
            {items.map(option => {
                const disLabel =
                    selLabelThree && selLabelTwo
                        ? `${option[selLabel]} ${option[selLabelTwo]} (${option[selLabelThree]
                        })`
                        : selLabelThree
                            ? `${option[selLabel]} (${option[selLabelThree]})`
                            : selLabelTwo
                                ? `${option[selLabel]} ${option[selLabelTwo]}`
                                : option[selLabel];
                return (
                    <MenuItem key={option.id} value={option[selValue]}>
                        {disLabel}
                    </MenuItem>
                );
            })}
        </TextField>
    );
};

const SelectBox = ({
    name,
    label,
    placeholder,
    disabled = false,
    onBlur = () => { },
    onChange = () => { },
    md = 4,
    xs = 12,
    value,
    items = [],
    selValue = "id",
    selLabel = "name",
    selLabelTwo,
    selLabelThree
}) => {
    return (
        <Grid item md={md} xs={xs}>
            <Field
                name={name}
                label={label || name}
                placeholder={placeholder || label}
                disabled={disabled}
                selValue={selValue}
                selLabel={selLabel}
                selLabelTwo={selLabelTwo}
                selLabelThree={selLabelThree}
                component={InputField}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                items={items}
            />
        </Grid>
    );
};

export default SelectBox;
