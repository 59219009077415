import React from "react";
import Typography from "@material-ui/core/Typography";

export default function RenderContact(props) {
  const catering = props.catering || {};
  const contact = catering.contact || {};
  const guest = catering.guest || {};
  const fullName = `${contact.firstName} ${contact.lastName}`;
  const phone = contact.phone ? `Ph: ${contact.phone}` : "";
  const email = contact.email ? `${contact.email}` : "";
  const seperator = contact.email && contact.phone ? " ," : "";
  const address = catering.address || {};
  const line1 = address.line1 ? `${address.line1} ${address.line2}` : "";
  const city = address.city ? `${address.city} ${address.state}` : "";
  const adult = guest.adults ? guest.adults : "";
  const kids = guest.kids ? ` (Kids: ${guest.kids})` : "";
  return (
    <>
      <Typography variant="subtitle2">{`${fullName}`}</Typography>
      <Typography variant="subtitle2">{`${phone} ${seperator} ${email}`}</Typography>
      {city && (
        <Typography variant="subtitle2">{`Address: ${line1} ${city}`}</Typography>
      )}
      <Typography variant="body1">{`Guest: ${adult}${kids}`}</Typography>
    </>
  );
}
