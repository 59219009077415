import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./configureStore";
import "./index.css";
import "./custom-styles.css";
import "./common.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./styles/theme";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
const sentryDns = process.env.REACT_APP_SENTRY_DNS;
const container = document.getElementById("root");

if (process.env.NODE_ENV === "production" && sentryDns) {
  console.log(" **** SENTRY INIT **** ", process.env.NODE_ENV);

  Sentry.init({
    dsn:
      "https://26597b76439e473cb73264f4fda0b9d0@o563436.ingest.sentry.io/5703406",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Provider>
);

serviceWorker.unregister();
