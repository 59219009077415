import React, { useState } from "react";
import _get from "lodash/get";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Form, Formik, Field } from "formik";
import InputBox from "../../../components/InputBox";
import SelectBox from "../../../components/SelectBox";
import Error from "../../Error";
import Success from "../../Success";
import { validate, initialValues } from "./validate";
import { updateMenuItem } from "../../../actions/menu/menuActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { RATEBY, STATUS } from "../../../constants";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  AlignCenter: {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-evenly",
  },
  AlignBtn: {
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  agriExm: {
    border: "1px solid grey",
    textTransform: "uppercase",
    flexBasis: "25%",
    flexShrink: 0,
    padding: 4,
    backgroundColor: "white",
    textAlign: "center",
    color: "black",
    display: "inline-block",
    width: 110,
    borderRadius: 5,
    fontWeight: 500,
  },
});

function EditModal(props) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState("");
  const [successMessage, setSuccess] = useState("");

  const { classes, data } = props;
  const isSmallScreen = window.innerWidth < 500;

  const save = async (params) => {
    try {
      setLoading(true);

      const response = await props.updateMenuItem(params);

      if (response.id) {
        setLoading(false);
        setSuccess("Menu added successfully");
        setTimeout(() => props.handleClose(), 2000);
      } else {
        setLoading(false);
        setError("Please try after sometime.");
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog
      fullScreen={isSmallScreen}
      fullWidth={true}
      maxWidth={"md"}
      className={classes.wrapper}
      open={!!props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" onClose={props.handleClose}>
        <Typography>{"EDIT"}</Typography>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={props.handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={{
            ...initialValues,
            ...data,
          }}
          validate={validate}
          onSubmit={save}
        >
          {({ values, handleSubmit, setFieldValue, handleChange }) => {
            return (
              <Form style={{ padding: "20px 10px" }}>
                <Grid container spacing={3}>
                  <InputBox md={4} name="name" label="Name" />
                  <SelectBox
                    md={4}
                    name="status"
                    label="Status"
                    selLabel={"name"}
                    selValue={"id"}
                    items={STATUS}
                  />
                  <InputBox
                    md={2}
                    name="price.amount"
                    label="Rate"
                    type="number"
                  />
                  <SelectBox
                    md={2}
                    name="price.by"
                    label="Rate BY"
                    selLabel={"name"}
                    selValue={"id"}
                    items={RATEBY}
                  />
                  <InputBox md={12} name="description" label="description" />
                  <Grid item md={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isCatering"
                          onChange={handleChange}
                          checked={values.isCatering}
                          inputProps={{ "aria-labelledby": "is-Catering-up" }}
                        />
                      }
                      label="Catering"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isDineIn"
                          onChange={handleChange}
                          checked={values.isDineIn}
                          inputProps={{ "aria-labelledby": "is-ine-up" }}
                        />
                      }
                      label="Dine In"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isPickup"
                          onChange={handleChange}
                          checked={values.isPickup}
                          inputProps={{ "aria-labelledby": "is-pick-up" }}
                        />
                      }
                      label="Pick Up"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Error top={20} message={errorMessage} />
                  <Success top={20} message={successMessage} />
                </Grid>

                <Grid
                  item
                  md={12}
                  xs={12}
                  style={{
                    marginTop: 45,
                    textAlign: "center",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    style={{
                      minWidth: 250,
                    }}
                  >
                    {loading ? "Loading..." : "Save"}
                  </Button>
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                  style={{
                    marginTop: 15,
                    textAlign: "center",
                  }}
                >
                  <Button onClick={props.handleClose}>{"Close"}</Button>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateMenuItem,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(EditModal)
);
