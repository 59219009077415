import React, { useEffect, useState } from "react";
import _get from "lodash/get";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { getTimeZone } from "../../../actions/momentActions";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import InputDateBox from "../../../components/InputDateBox";
import InputBox from "../../../components/InputBox";
import SelectBox from "../../../components/SelectBox";
import Error from "../../Error";
import Success from "../../Success";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getTbBills, saveTbBill } from "../../../actions/bills/billsActions";
import { getBills, deleteBill } from "../../../actions/bills/billsActions";
import validate from "./validate";
import { ACCEPT_OPTIONS, ONSITE_OFFSITE, MENU_TYPES } from "../../../constants";
import TbTable from "./TbTable";
import RenderTotal from "../RenderTotal";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

function AcceptInquiry(props) {
  const [errorMessage, setError] = useState("");
  const [successMessage, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({});
  const [bills, setBills] = useState([]);
  const [billsSaved, setBillsSaved] = useState([]);
  const isSmallScreen = window.innerWidth < 500;

  const _getSavedBills = async (values) => {
    const bills = await props.getBills(params);

    setBillsSaved(bills);
  };

  const _getTbBills = async (values) => {
    try {
      setLoading(true);
      const start = moment(values.start).valueOf().toString().slice(0, 10);
      const end = moment(values.end).valueOf().toString().slice(0, 10);
      let params = { start, end };

      if (values.key) {
        params.key = values.key;
      }
      if (values.venueId) {
        params.venueId = values.venueId;
      }
      const _response = await props.getTbBills(params);
      const _params = {
        start: values.start,
        end: values.end,
        take: 500,
        // fields: ["id", "bill_id"],
      };
      const bills = await props.getBills(_params);

      setParams(_params);
      setBillsSaved(bills);

      setLoading(false);
      if (_response.count && _response.bills) {
        const response = _response.bills.map((item) => {
          item.billObj = bills.find((_bill) => _bill.bill_id === item.bill_id);
          return item;
        });
        setBills(response);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const { classes, constants } = props;

  return (
    <Dialog
      fullScreen={true}
      fullWidth={true}
      className={classes.wrapper}
      open={!!props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" onClose={props.handleClose}>
        <Typography>{"Search"}</Typography>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={props.handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={{
            venueId: "39165",
            start: moment().startOf("month").format("YYYY-MM-DD hh:mm"),
            end: moment().endOf("month").format("YYYY-MM-DD hh:mm"),
          }}
          enableReinitialize={true}
          onSubmit={_getTbBills}
        >
          {({ values, handleSubmit, setFieldValue }) => {
            return (
              <Form style={{ padding: "0px 10px" }}>
                <Grid container spacing={3}>
                  <InputDateBox md={2} name="start" label="Start" />
                  <InputDateBox md={2} name="end" label="End" />
                  <InputBox md={2} name="venueId" label="Venue" />
                  <InputBox md={5} name="key" label="Key" />
                  <Grid item md={1} xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                      size="small"
                      style={{ marginTop: 12, minWidth: 125 }}
                    >
                      {loading ? "Loading..." : "LOAD"}
                    </Button>
                  </Grid>
                </Grid>
                {successMessage && <Success message={successMessage} />}
                {errorMessage && <Error message={errorMessage} />}
              </Form>
            );
          }}
        </Formik>
        <RenderTotal items={billsSaved} isCategory={false} isDaily={true} />
        <TbTable
          bills={bills}
          saveTbBill={props.saveTbBill}
          afterSave={_getSavedBills}
          deleteBill={props.deleteBill}
        />
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "equipmentData.error", ""),
    loading: _get(state, "accountData.accountCreateBusy", false),
    accountValues: _get(state, "form.accountForm.values", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getBills, getTbBills, saveTbBill, deleteBill }, dispatch);

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AcceptInquiry)
);
