import { React } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { getOrders } from "../../actions/orderActions"

const styles = theme => ({
    paper: {
        margin: 15,
        overflow: "hidden"
    },
    searchBar: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
    },
    searchInput: {
        fontSize: theme.typography.fontSize
    },
    block: {
        display: "block"
    },
    addUser: {
        marginRight: theme.spacing(1)
    },
    contentWrapper: {
        margin: "40px 16px"
    }
});

function Content(props) {
    const { classes } = props;

    return (
        <Paper className={classes.paper}>
            <div className={classes.contentWrapper}>
                {/* {loading ? (
                    <div style={{ textAlign: "center" }}>
                        <CircularProgress />
                    </div>
                ) : ( */}
                <h1>Welcome</h1>
                {/* )} */}
            </div>
        </Paper >
    );
}

const mapStateToProps = state => {
    return {
        classes: PropTypes.object.isRequired
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getOrders
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Content)
);
