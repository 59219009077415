// import _get from "lodash/get";
import { React, useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import {
  getContactCount,
  getContact,
} from "../../actions/contact/contactActions";
import ContactTable from "./ContactTable";

const styles = (theme) => ({
  paper: {
    margin: 15,
    overflow: "hidden",
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: "block",
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: "40px 16px",
  },
});

function Catering(props) {
  const { classes } = props;
  const [contacts, setContacts] = useState([]);
  const [count, setContactCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  useEffect(() => {
    _getContactCount();
    _getContact({ skip: 0, take: rowsPerPage });
    // eslint-disable-next-line
  }, []);

  async function _getContactCount() {
    let response = await props.getContactCount();
    setContactCount(response);
  }

  async function _getContact(params) {
    setLoading(true);
    let response = await props.getContact(params);

    setContacts(response);
    setLoading(false);
  }

  const handleChange = (_page, _rowsPerPage) => {
    setPage(_page);
    setRowsPerPage(_rowsPerPage);
    let skip = parseInt(_page) * parseInt(_rowsPerPage);
    let params = { skip, take: parseInt(_rowsPerPage) };
    _getContact(params);
  };

  return (
    <Paper className={classes.paper}>
      <AppBar
        className={classes.searchBar}
        position="static"
        color="default"
        elevation={0}
      ></AppBar>
      <div className={classes.contentWrapper}>
        <div style={{ textAlign: "right" }}></div>

        {loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        )}
        <ContactTable
          items={contacts}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChange={handleChange}
        />
      </div>
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return {
    classes: PropTypes.object.isRequired,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getContactCount,
      getContact,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Catering)
);
