import React from "react";
import classNames from "classnames";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useLocation, useNavigate } from "react-router-dom";

export default function MainNavigatorLinks(props) {
    const navigate = useNavigate()
    const location = useLocation()

    const onNavClick = url => {
        navigate(url);
        props.onClose();
    };

    const { show, index } = props
    const {
        appRoutes = [],
        classes,
        isAdmin,
    } = props;
    const currentRoute = location.pathname

    return (
        <div key={index}>
            {appRoutes.map(({ id, children, url }) => {

                return (
                    <React.Fragment key={id}>
                        {children.map(
                            ({
                                id: childId,
                                url: childUrl,
                                icon,
                                active,
                                admin,
                                manager,
                                driver
                            }) => {
                                let urlFinal = childUrl
                                if (isAdmin) {
                                    if (!admin) {
                                        return null;
                                    }
                                }
                                return (
                                    <ListItem
                                        button
                                        dense
                                        key={childId}
                                        className={classNames(
                                            classes.item,
                                            classes.itemActionable,
                                            currentRoute === urlFinal && classes.itemActiveItem,
                                            currentRoute === urlFinal && show && "activeItem"
                                        )}
                                        url={urlFinal}
                                        onClick={() =>
                                            onNavClick(urlFinal)
                                        }
                                        style={{ minHeight: 36 }}
                                    >
                                        <ListItemIcon
                                            style={{ marginRight: 16, fontSize: 24, minWidth: "auto" }}
                                        >
                                            {icon}
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{
                                                primary: classes.itemPrimary
                                            }}
                                        >
                                            {show ? childId : ""}
                                        </ListItemText>
                                    </ListItem>
                                );
                            }
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
}

MainNavigatorLinks.defaultProps = {
    onClose: () => { }
};
