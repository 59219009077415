import { React, useState, useEffect } from "react";
import _get from "lodash/get";
import { useParams } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Form, Formik, Field } from "formik";
import InputBox from "../../../../components/InputBox";
import SelectBox from "../../../../components/SelectBox";
import Error from "../../../Error";
import Success from "../../../Success";
import { validate, initialValues } from "./validate";
import {
  addGroupItem,
  deleteGroupItem,
  getMenusItems,
} from "../../../../actions/menu/menuActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { STATUS, COURSES, SALE_TYPE, RESTRICIONS } from "../../../../constants";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  AlignCenter: {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-evenly",
  },
  AlignBtn: {
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  agriExm: {
    border: "1px solid grey",
    textTransform: "uppercase",
    flexBasis: "25%",
    flexShrink: 0,
    padding: 4,
    backgroundColor: "white",
    textAlign: "center",
    color: "black",
    display: "inline-block",
    width: 110,
    borderRadius: 5,
    fontWeight: 500,
  },
});

function GroupItemModal(props) {
  const _groupitems = (props.data && props.data.groupitems) || [];
  const selectedCur = _groupitems.map((ee) => ee.itemId);
  let params = useParams();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setError] = useState("");
  const [successMessage, setSuccess] = useState("");
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState(selectedCur);

  const { classes, data } = props;
  const isSmallScreen = window.innerWidth < 500;

  async function getItems() {
    try {
      const response = await props.getMenusItems({ skip: 0, take: 100 });
      setItems(response);
    } catch (e) {
      console.log("e :: ", e);
    }
  }
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);

  const save = async (checked, item) => {
    try {
      const groupId = params.groupId.toString();
      const itemId = item.id.toString();
      const payload = { groupId: params.groupId, itemId: item.id };

      setSaving(true);

      let response;
      if (checked) {
        response = await props.addGroupItem(payload);
      } else {
        const _sel = _groupitems.find(
          (_item) =>
            _item.groupId.toString() === groupId &&
            _item.itemId.toString() === itemId
        );

        payload.id = _sel.id;
        response = await props.deleteGroupItem(payload);
      }

      if (response.id || response.affected) {
        setSaving(false);
        setSuccess("Updated successfully");
        // setTimeout(() => props.handleClose(), 2000);
      } else {
        setSaving(false);
        setError("Please try after sometime.");
      }
    } catch (e) {
      console.log(e);
    }
  };

  async function updateChange(event, item) {
    const checked = event.target.checked;
    save(checked, item);
    if (checked) {
      let _selected = [...selected];
      _selected.push(item.id);
      setSelected(_selected);
    } else {
      let _selected = selected.filter((_id) => _id !== item.id);
      setSelected(_selected);
    }
  }

  return (
    <Dialog
      fullScreen={isSmallScreen}
      fullWidth={true}
      maxWidth={"md"}
      className={classes.wrapper}
      open={!!props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" onClose={props.handleClose}>
        <Typography>{"Choose Items"}</Typography>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={props.handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={{
            ...initialValues,
            ...data,
          }}
          validate={validate}
          onSubmit={save}
        >
          {({ values, handleSubmit, setFieldValue }) => {
            return (
              <Form style={{ padding: "20px 10px" }}>
                <Grid container spacing={2}>
                  <Error top={20} message={errorMessage} />
                  <Success top={20} message={successMessage} />
                </Grid>
                <Grid container spacing={2}>
                  {items &&
                    items.map((item) => (
                      <Grid key={`${item.id}`} item md={12} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isCatering"
                              disabled={saving}
                              onChange={(e) => updateChange(e, item)}
                              checked={selected.includes(item.id)}
                              inputProps={{
                                "aria-labelledby": "is-Catering-up",
                              }}
                            />
                          }
                          label={item.name}
                        />
                      </Grid>
                    ))}
                </Grid>

                <Grid
                  item
                  md={12}
                  xs={12}
                  style={{
                    marginTop: 15,
                    textAlign: "center",
                  }}
                >
                  <Button onClick={props.handleClose}>{"Close"}</Button>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addGroupItem,
      deleteGroupItem,
      getMenusItems,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(GroupItemModal)
);
