import React, { useEffect, useState } from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import { getTimeZone } from "../../actions/momentActions";
import Grid from "@material-ui/core/Grid";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import Error from "../Error";
import Success from "../Success";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { addDevice } from "../../actions/deviceActions";
import { getUsers } from "../../actions/userActions";
import validate from "./validate"

const styles = theme => ({
    wrapper: {},
    DialogActions: {
        marginTop: 20
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

function AddDevice(props) {
    const [errorMessage, setError] = useState("")
    const [successMessage, setSuccess] = useState("")
    const [loading, setLoading] = useState(false)
    const isSmallScreen = window.innerWidth < 500;
    const [users, setUsers] = useState([])

    useEffect(() => {
        getAllUsers({ skip: 0, limit: 250 });
        // eslint-disable-next-line
    }, [])

    async function getAllUsers() {
        let response = await props.getUsers();
        setUsers(response)
    }

    const save = async params => {
        setLoading(true)
        let response
        if (!params._id) {
            response = await props.addDevice(params);
        } else {
            params = { payload: params, id: params._id }
            response = await props.addDevice(params);

        }
        if (response._id) {
            setLoading(false)
            setSuccess("Order created successfully")
            setTimeout(() => props.onClose(), 2000);
        } else {
            setLoading(false)
            setError("Please try after sometime.")
        }
    };

    const { classes } = props;

    const initialValues = {
        userId: "",
        locationId: "",
        deviceName: ""
    };

    return (<Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"md"}
        className={classes.wrapper}
        open={!!props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle
            id="alert-dialog-title"
            onClose={props.handleClose}
        >
            <Typography>
                Add Device
            </Typography>
            <IconButton
                aria-label="Close"
                className={classes.closeButton}
                onClick={props.handleClose}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>

        <DialogContent>
            <Formik
                initialValues={initialValues}
                validate={validate}
                onSubmit={save}
                enableReinitialize={true}
            >
                {({ values, handleSubmit, setFieldValue }) => {
                    return (
                        <Form style={{ padding: "20px 10px" }}>
                            <Grid container spacing={3}>
                                <SelectBox
                                    md={6}
                                    name="userId"
                                    label="User"
                                    selLabel={"firstName"}
                                    selValue={"_id"}
                                    // selLabelThree={"firstName"}
                                    items={users}
                                />
                                <InputBox name="deviceName" label="Device Name" md={6} />
                            </Grid>
                            {successMessage && (
                                <Success message={successMessage} />
                            )}
                            {errorMessage && (
                                <Error message={errorMessage} />
                            )}
                            <div>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                    style={{ marginTop: 12 }}
                                >
                                    {loading ? "Saving..." : "SAVE"}
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>

        </DialogContent>
    </Dialog >
    );
}

const mapStateToProps = state => {
    return {
        constants: _get(state, "appData.constants", {}),
        errorMessage: _get(state, "equipmentData.error", ""),
        loading: _get(state, "accountData.accountCreateBusy", false),
        accountValues: _get(state, "form.accountForm.values", {})
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getUsers,
            addDevice,
            getTimeZone
        },
        dispatch
    );

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddDevice))