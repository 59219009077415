import { React, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import Divider from '@material-ui/core/Divider';
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { getMenus, getMenusGroups } from "../../../actions/menu/menuActions";
import { CircularProgress } from "@material-ui/core";
import { Link, useSearchParams } from "react-router-dom";
import AddMenuGroup from "./AddMenuGroup";
import GroupItemModal from "./MenuGroupDetail/GroupItemModal";

const styles = (theme) => ({
  paper: {
    margin: 15,
    overflow: "hidden",
  },
  title: {
    flexGrow: 1,
  },
});

function MenuGroup(props) {
  const { classes } = props;
  const [loading, setLoading] = useState(false);

  const [groups, setGroups] = useState([]);
  const [selGroup, setSelGroup] = useState({});

  const [isAdd, setIsAdd] = useState(false);
  const [isDetail, setIsDetail] = useState(false);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    getGroups();
    // eslint-disable-next-line
  }, []);

  async function getGroups() {
    try {
      setLoading(true);
      let response = await props.getMenusGroups();
      if (response.length > 0) {
        setGroups(response);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  async function setGroupDetail(_group) {
    try {
      setLoading(true);

      let response = await props.getMenusGroups({ id: _group.id });
      if (response && response.id) {
        setSelGroup(response);
        setIsDetail(true);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <>
      {isAdd && (
        <AddMenuGroup
          open={isAdd}
          data={selGroup}
          handleClose={() => {
            getGroups();
            setIsAdd(false);
            setSelGroup(null);
          }}
        />
      )}

      {isDetail && (
        <GroupItemModal
          data={selGroup}
          open={isDetail}
          handleClose={() => {
            getGroups();
            setSelGroup(null);
            setIsDetail(false);
          }}
        />
      )}

      <Card className={classes.root}>
        <CardContent>
          <Grid container className={classes.root} spacing={2}>
            <Grid item md={12} xs={12}>
              <AppBar position="static">
                <Toolbar>
                  <Typography
                    variant="h6"
                    className={classes.title}
                  >{`Total ${groups.length}`}</Typography>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setIsAdd(true);
                    }}
                  >
                    ADD
                  </Button>
                </Toolbar>
              </AppBar>
            </Grid>
            <Grid item md={12} xs={12}>
              {loading && (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress />
                </div>
              )}
              <List className={classes.root}>
                {groups.map((group) => (
                  <div key={group.id}>
                    <ListItem alignItems="flex-start">
                      <ListItemText primary={group.name} secondary={""} />
                      <ListItemSecondaryAction>
                        <ButtonGroup
                          color="primary"
                          size="small"
                          aria-label="outlined primary button group"
                        >
                          <Button
                            type="button"
                            onClick={() => {
                              setIsAdd(true);
                              setSelGroup(group);
                            }}
                          >
                            {"EDIT"}
                          </Button>
                          <Button
                            type="button"
                            onClick={() => {
                              setGroupDetail(group);
                            }}
                          >
                            {"ITEMS"}
                          </Button>
                          <Button type="button">
                            <Link
                              to={`/menu-group/${group.id}`}
                              style={{
                                textDecoration: "none",
                                color: "#009BE5",
                              }}
                            >
                              {"DETAIL"}
                            </Link>
                          </Button>
                        </ButtonGroup>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </div>
                ))}
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMenus,
      getMenusGroups,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MenuGroup)
);
