import React, { useState } from "react";
import _get from "lodash/get";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Form, Formik } from "formik";
import InputBox from "../../../../components/InputBox";
import SelectBox from "../../../../components/SelectBox";
import Error from "../../../Error";
import Success from "../../../Success";
import { validate, initialValues } from "./validate";
// import { addMenu, updateMenu } from "../../../actions/menu/menuActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { STATUS } from "../../../../constants";
import CateringEdit from "./CateringEdit";

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  AlignCenter: {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-evenly",
  },
  AlignBtn: {
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  agriExm: {
    border: "1px solid grey",
    textTransform: "uppercase",
    flexBasis: "25%",
    flexShrink: 0,
    padding: 4,
    backgroundColor: "white",
    textAlign: "center",
    color: "black",
    display: "inline-block",
    width: 110,
    borderRadius: 5,
    fontWeight: 500,
  },
});

function EditModal(props) {
  // const [loading, setLoading] = useState(false);
  // const [errorMessage, setError] = useState("");
  // const [successMessage, setSuccess] = useState("");
  const { classes, editMenu } = props;

  // const save = async (params) => {
  //   setLoading(true);
  //   let response;
  //   if (!params.id) {
  //     // response = await props.addMenu(params);
  //   } else {
  //     // response = await props.updateMenu(params);
  //   }
  //   if (response.id) {
  //     setLoading(false);
  //     setSuccess("Menu is added successfully");
  //     setTimeout(() => props.onClose(), 2000);
  //   } else {
  //     setLoading(false);
  //     setError("Please try after sometime.");
  //   }
  // };

  return (
    <Dialog
      fullScreen={true}
      className={classes.wrapper}
      open={!!props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" onClose={props.handleClose}>
        <Typography><strong>{"UPDATE"}</strong></Typography>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={props.handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <CateringEdit data={props.data} handleClose={props.handleClose} />
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(EditModal)
);
